import { Injectable } from '@angular/core';

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { AuthService } from '@services/auth';
import { FuseConfigService } from '@fuse/services/config.service';
import { LocalStorageService } from 'ngx-webstorage';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  private isDesktopDevice: boolean = true;
  constructor(
    private _authService: AuthService,
    private fuseConfig: FuseConfigService,
    private localStorageService: LocalStorageService,
    private deviceService: DeviceDetectorService,
    private router: Router
  ) {
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.processAuthentication();
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.processAuthentication();
  }

  public processAuthentication(): boolean {
    if (!this._authService.isAuthenticated) {
      this.fuseConfig.setLayoutNone();
      this.localStorageService.store('tempUrl', window.location.pathname);
      this.router.navigate(['auth', 'login']);
      return false;
    } else if (this._authService.currentUser && this._authService.currentUser.isFirstLogin && this.isDesktopDevice) {
      this.fuseConfig.setLayoutNone();
      this.router.navigate(['auth', 'changepassword']);
      return false;
    }
    return true;
  }
}
