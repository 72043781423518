import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Permission } from '@common/models/permission.model';
import { AppConfig, AppConfigService } from '@common/modules/share/appConfig.service';
import { AuthService } from '@common/services';
@Component({
  selector: 'home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomePageComponent implements OnInit {
  public appConfig: AppConfig;
  public dashboardPermission: boolean = false;
  constructor(private _appConfigService: AppConfigService, private _authService: AuthService) {
    this.appConfig = this._appConfigService.appConfig;
  }

  ngOnInit(): void {
    this.dashboardPermission = this._authService.checkPermission(Permission.DASHBOARD.OVERVIEW);
  }
}
