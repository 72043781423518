import { IDepartment, IPlant } from './common.model';

export class User {
  public id?: string;
  public userName?: string; // TODO: use one
  public userId?: string;
  public userGuid?: string;
  public fullName?: string;
  public userRoleIds: number[];
  public permission?: string;
  public position?: string;
  public email?: string;
  public phoneNumber?: string;
  public genderId?: number;
  public latestLoggedin?: string;
  public isActive?: boolean;
  public qrCode?: string;
  public username?: string;
  public plants: IPlant[];
  public departments: IDepartment[];
  public isFirstLogin: boolean;
  public avatar: string;
  public avatarImage: string;
  public isSso?: boolean;
  public isSupperAdmin?: boolean;
  public timeoutExpired?: number;

  constructor(user?) {
    user = user || {};
    this.id = user.id;
    this.userName = user.userName;
    this.userId = user.userId;
    this.userGuid = user.userGuid;
    this.fullName = user.fullName;
    this.userRoleIds = user.userRoleIds || [];
    this.permission = user.permission;
    this.position = user.position;
    this.email = user.email;
    this.phoneNumber = user.phoneNumber;
    this.genderId = user.genderId;
    this.latestLoggedin = user.latestLoggedin;
    this.isActive = user.isActive;
    this.qrCode = user.qrCode || '';
    this.username = user.username;
    this.avatar = user.avatar;
    //this.isSso = !!user.isSso;
    this.plants = user.plants || [];
    this.departments = user.departments || [];
    // TODO: BE will remove deparmentIds and plantIds, FE follow
    // if (typeof user.departmentIds === 'string') {
    //   this.departmentIds = user.departmentIds ? user.departmentIds.split(',') : [];
    // } else if (Array.isArray(user.departmentIds)) {
    //   this.departmentIds = user.departmentIds;
    // }
    // if (typeof user.plantIds === 'string') {
    //   this.plantIds = user.plantIds ? user.plantIds.split(',') : [];
    // } else if (Array.isArray(user.plantIds)) {
    //   this.plantIds = user.plantIds;
    // }
    // if (user.plants && Array.isArray(user.plants) && !this.plantIds) {
    //   this.plantIds = user.plants.map((x) => x.id);
    // }
    // if (user.departments && Array.isArray(user.departments) && !this.departmentIds) {
    //   this.departmentIds = user.departments.map((x) => x.id);
    // }
    this.timeoutExpired = user.timeoutExpired;
  }
}
