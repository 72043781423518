import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { SHARED_SERVICES } from './services';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouteCachingModule } from '@modules/route-caching';
import { PromiseButtonModule } from '@modules/promise-button/promise-button.module';
// import { MaxNumberModule } from '@modules/max-number';
// import { LimitToModule } from '@modules/limit-to';
// import { InputDecimalModule } from '@modules/input-decimal';
import { NgPickDatetimeModule } from './modules/ng-pick-datetime';
import { BaseModule } from './modules/base';
import { GenericListModule } from './modules/generic-list/generic-list.module';
import { GenericDialogModule } from './modules/generic-dialog/generic-dialog.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseFullscreenModule } from './modules/fullscreen';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatChipsModule } from '@angular/material/chips';
import { NgxMaskModule } from 'ngx-mask';
import { AppDirectiveModule } from './directives';
// import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@modules/ng-pick-datetime/picker';
// import { MY_OWL_FORMATS } from '@common/models/CommonConstant';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FuseSharedModule,
    RouteCachingModule,
    PromiseButtonModule,
    NgPickDatetimeModule,
    NgxMatSelectSearchModule,
    BaseModule,
    GenericListModule,
    GenericDialogModule,
    MatChipsModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'xsrf-token',
      headerName: 'X-XSRF-TOKEN',
    }),
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    FuseFullscreenModule,
    NgxMaskModule,
    AppDirectiveModule,
  ],
  exports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    FuseSharedModule,
    RouteCachingModule,
    PromiseButtonModule,
    NgPickDatetimeModule,
    NgxMatSelectSearchModule,
    BaseModule,
    GenericListModule,
    GenericDialogModule,
    InfiniteScrollModule,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    FuseFullscreenModule,
    NgxMaskModule,
    AppDirectiveModule,
  ],
  providers: [
    ...SHARED_SERVICES,
    // {provide: OWL_DATE_TIME_LOCALE, useValue: 'vi'},
    // {provide: OWL_DATE_TIME_FORMATS, useValue: MY_OWL_FORMATS},
    // {provide: OwlDateTimeIntl, useClass: DefaultIntl},
  ],
})
export class AppSharedModule {}
