import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Util } from '../util';
import { Router } from '@angular/router';
import { AppConstant } from '../appConstant';
import { AuthService } from '../auth';
import { Broadcaster, BroadcastKey } from '@common/modules/share/broadcaster.service';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: any;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
  noIntercept?: boolean;
  skipErrorMessage?: boolean;
}
export interface IApiResponse {
  errorMessages?: string[];
  messages?: string[];
  result?: 'Success' | 'Error' | string;
}

export interface IApiListResponse<T> extends IApiResponse {
  items?: T[];
  totalData?: number;
  total?: number;
}

@Injectable()
export class CustomHttpClient {
  public refreshTokenSubject: Subject<string>;

  constructor(private http: HttpClient, private _util: Util, private _authService: AuthService, private _router: Router, private _broadcaster: Broadcaster) {}

  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.intercept<T>(this.http.get<T>(AppConstant.domain + '/api' + endPoint, options), options);
  }

  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Post<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    if (options && options.noIntercept) {
      return this.http.post<T>(endPoint, params, options);
    } else {
      return this.intercept<T>(this.http.post<T>(AppConstant.domain + '/api' + endPoint, params, options));
    }
  }

  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Put<T>(endPoint: string, params: Object, options?: IRequestOptions): Observable<T> {
    return this.intercept<T>(this.http.put<T>(AppConstant.domain + '/api' + endPoint, params, options));
  }

  public Patch<T>(endPoint: string, body?: object, options?: IRequestOptions): Observable<T> {
    if (options && options.noIntercept) {
      return this.http.patch<T>(endPoint, body, options);
    } else {
      return this.intercept<T>(this.http.patch<T>(AppConstant.domain + '/api' + endPoint, body, options));
    }
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.intercept<T>(this.http.delete<T>(AppConstant.domain + '/api' + endPoint, options));
  }

  public intercept<T>(observable: Observable<T>, options?: IRequestOptions): Observable<T> {
    return new Observable<T>((subscriber) => {
      observable.subscribe({
        next: (data) => {
          // subscribe
          subscriber.next(data);
        },
        error: (err) => {
          // error
          if (err.status === 401) {
            if (!this.refreshTokenSubject) {
              this.refreshTokenSubject = new Subject<string>();
            }
            this.refreshToken().subscribe({
              next: (token) => {
                this.refreshTokenSubject.next(token);
              },
              error: (error) => {
                console.log('refresh token failed', error);
                this._authService.clearToken();
                this._router.navigateByUrl('auth/login');
                subscriber.error(error);
              },
            });
            this.refreshTokenSubject.subscribe(() => {
              this.intercept(observable).subscribe({
                next: (data) => subscriber.next(data),
                error: (error) => subscriber.error(error),
                complete: () => subscriber.complete(),
              });
            });
          } else {
            this._broadcaster.fire(BroadcastKey.PROGRESSING, false);
            if (
              err.status === 400 &&
              err.error &&
              err.error.errorMessages &&
              (err.error.errorMessages[0] === 'Permission.NotPermission' || err.error.errorMessages[0] === 'permission.notpermission')
            ) {
              // TODO: process in case not permission
              setTimeout(() => {
                this._authService.logout();
              }, 500);
            }
            if (!options || !options.skipErrorMessage) {
              this._util.showError(err);
            }
            subscriber.error(err);
          }
        },
        complete: () => {
          // complete
          subscriber.complete();
        },
      });
    });
  }

  public refreshToken() {
    const userToken = this._authService.getToken();
    const refreshToken = userToken ? userToken.refreshToken : null;
    const httpParams = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('refresh_token', refreshToken)
      .set('client_id', AppConstant.clientId)
      .set('client_secrect', AppConstant.clientSecret);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    });

    return this.http.post(`${AppConstant.domain}/api/token`, httpParams, { headers }).pipe(
      // map((response: any) => response.data),
      tap((responseData: any) => {
        this._authService.setToken(responseData.access_token, responseData.refresh_token);
      })
    );
  }

  public sendPushNotification(data) {
    if (isDevMode()) {
      return of({});
    }
    // this push for other project, DON'T USE this app_id
    // REST API KEYS: ZmNjYjk3MDEtYTU2Ny00ZDAyLTg5Y2ItOTU5OTYyYjhlOTJl
    // APP ID: b66a2459-f49b-4efe-80e2-a61eae8c4717
    const message = {
      app_id: '2bb81cde-1669-486d-91eb-16e574f8955f',
      contents: { en: data || 'Order updated' },
      included_segments: ['All'],
      small_icon: 'ic_stat_onesignal_default',
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': 'Basic NDMxNjE0NmYtMTY0NS00NTBhLTk5M2UtYzdiYTE0MDkxOTYx',
    });
    const options = {
      headers: headers,
      body: message,
      noIntercept: true,
    };
    return this.Post('https://onesignal.com/api/v1/notifications', message, options);
  }

  public uploadHandler = (blobInfo, success, failure) => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', AppConstant.domain + '/api/commons/uploadImage');
    xhr.setRequestHeader('Authorization', 'Bearer ' + this._authService.getToken().accessToken);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function () {
      // todo: test result after backend implement API
      let json;
      if (xhr.status !== 200) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }
      json = JSON.parse(xhr.responseText);
      if (!json || typeof json.url !== 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }
      success(json.url);
    };
    const reader = new FileReader();
    reader.onload = (event: any) => {
      // console.log(event);
      // const formData = new FormData();
      // formData.append('base64String', event.target.result);
      // formData.append('fileName', blobInfo.filename());
      // xhr.send(formData);
      const idx = event.target.result.indexOf('base64,');
      const base64 = event.target.result.substr(idx >= 0 ? idx + 7 : 0);
      // xhr.send(this.transformRequestHandler({'base64String': event.target.result, 'fileName': blobInfo.filename()}));
      xhr.send(JSON.stringify({ base64String: base64, fileName: blobInfo.filename() }));
    };
    reader.readAsDataURL(blobInfo.blob()); //Convert the blob from clipboard to base64
  };
}
