<!--<h1 matDialogTitle>{{'CONFIRM' | translate}}</h1>-->
<!--<div class="dialog_content">-->
<!--<div mat-dialog-content>{{confirmMessage}}</div>-->
<!--<div style="width: 100%" mat-dialog-actions class="dialog-theme pt-24">-->
<!--<button mat-raised-button color="primary" (click)="dialogRef.close(true)">{{'YES' | translate}}</button>-->
<!--<button mat-raised-button color="warn" (click)="dialogRef.close(false)">{{'CANCEL' | translate}}</button>-->
<!--</div>-->
<!--</div>-->
<div class="dialog-content-wrapper">
  <div matDialogTitle class="mat-red-bg m-0">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <span class="h1 pl-12">{{ title || 'CONFIRM' | translate }}</span>
      <button class="m-8" mat-icon-button (click)="dialogRef.close(false)"><mat-icon>close</mat-icon></button>
    </div>
  </div>

  <div mat-dialog-content>
    <div class="confirm-message">{{ confirmMessage }}</div>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
    <button mat-raised-button class="mat-green-bg white-color" (click)="dialogRef.close(true)">{{ 'YES' | translate }}</button>
    <button *ngIf="!removeButtonCancel" mat-raised-button (click)="dialogRef.close(false)">{{ 'CANCEL' | translate }}</button>
  </div>
</div>
