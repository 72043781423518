import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'pages',
    pathMatch: 'full',
  },
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/mobile.module').then((m) => m.MobileModule),
    // data: { permission: Permission.FORMCONTROLVALUE.READ },
    // canActivateChild: [PermissionGuard],
  },
  {
    path: 'auth',
    children: [
      {
        path: 'changepassword',
        loadChildren: () => import('./unAuth/changepassword/changepassword.module').then((m) => m.ChangePasswordModule),
      },
      {
        path: 'login',
        loadChildren: () => import('./unAuth/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'login-redirect/:id',
        loadChildren: () => import('./unAuth/login-redirect/login-redirect.module').then((m) => m.LoginRedirectModule),
      },
      // {
      //   path: 'register',
      //   loadChildren: () => import('./unAuth/register/register.module').then((m) => m.RegisterModule),
      // },
      {
        path: 'error',
        loadChildren: () => import('./unAuth/404/error-404.module').then((m) => m.Error404Module),
      },
      {
        path: 'notpermission',
        loadChildren: () => import('./unAuth/notpermission/notpermission.module').then((m) => m.NotPermissionModule),
      },
      {
        path: '**',
        redirectTo: 'error',
      },
    ],
  },
  {
    path: 'public',
    loadChildren: () => import('./public/public.module').then((m) => m.PublicModule),
  },
  {
    path: '**',
    redirectTo: 'auth/error',
  },
];
