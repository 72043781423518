import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { pageRoutes } from './routes';
import { PagesComponent } from '@pages/pages.component';
import { UserIdleModule } from '@modules/user-idle';
import { SignalRConfiguration, SignalRModule } from 'ng2-signalr';
import { AppConstant } from '@services/index';
import { NotificationService } from '@common/services/business';
import { HomePageComponent } from './home.component';
import { CommonModule } from '@angular/common';
import { NgxPowerBiModule } from '@common/modules/ngx-powerbi/ngx-powerbi.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PlantService } from '@common/services/business/plant.service';

export function createConfig(): SignalRConfiguration {
  const c = new SignalRConfiguration();
  c.hubName = 'notifications';
  c.url = AppConstant.domain;
  c.logging = true;
  // >= v5.0.0
  c.executeEventsInZone = true; // optional, default is true
  c.executeErrorsInZone = false; // optional, default is false
  c.executeStatusChangeInZone = true; // optional, default is true
  return c;
}

@NgModule({
  declarations: [PagesComponent, HomePageComponent],
  imports: [
    RouterModule.forChild(pageRoutes),
    // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
    // Default values: `idle` is 1800 (30 minutes), `timeout` is 3 (3 seconds)
    // and `ping` is 30 (30 seconds).
    NgxPowerBiModule,
    CommonModule,
    UserIdleModule.forRoot({ idle: 7200, timeout: 3, ping: 30 }),
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    SignalRModule.forRoot(createConfig),
  ],
  providers: [NotificationService, PlantService],
})
export class PageModule {}
