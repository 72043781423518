import { Directive, Input, OnInit, AfterContentChecked, AfterViewInit, ElementRef } from '@angular/core';
import { LineChartComponent } from '@swimlane/ngx-charts';

@Directive({
  selector: '[clickChartLegend]',
})
export class ClickChartLegendDirective implements AfterViewInit {
  @Input('clickChartLegend') chart: LineChartComponent;
  sourceData = [];

  constructor(public element: ElementRef) {}

  ngAfterViewInit() {
    // console.log('ngAfterViewInit', this.chart, this.element);
    const dom: HTMLElement = this.element.nativeElement;
    const legendLabels = dom.querySelectorAll('.legend-label-text');
    this.sourceData = JSON.parse(JSON.stringify(this.chart.results));
    this.chart.select.subscribe((event) => {
      // console.log('select', event);
      const temp = JSON.parse(JSON.stringify(this.chart.results));
      if (this.isDataShown(event)) {
        //Hide it
        temp.some((d) => {
          if (d.name === event) {
            d.series = [];
            return true;
          }
        });
        legendLabels.forEach((d) => {
          if (d.textContent && d.textContent.trim() === event) {
            d.className = d.className + ' text-strike';
          }
        });
      } else {
        //Show it back
        const dataToAdd = this.sourceData.filter((d) => d.name === event);
        temp.some((d) => {
          if (d.name === event) {
            d.series = dataToAdd[0].series;
            return true;
          }
        });
        legendLabels.forEach((d) => {
          if (d.textContent && d.textContent.trim() === event) {
            d.className = 'legend-label-text';
          }
        });
      }
      // console.log('log temp: ' + JSON.stringify(temp));
      this.chart.results = temp;
      this.chart.update();
    });
  }

  isDataShown(name) {
    const selectedData = this.chart.results.filter((d) => d.name === name && d.series[0] !== undefined);
    return selectedData && selectedData.length > 0;
  }
}
