import { Component, OnInit, Inject, HostListener, Input, ElementRef, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'scroll-to-top',
  templateUrl: './scrollToTop.component.html',
  animations: fuseAnimations,
})
export class ScrollToTopComponent implements OnInit, OnDestroy {
  @Input() scrollContent: Element;
  destroy = new Subject();
  destroy$ = this.destroy.asObservable();
  isScrolled: boolean;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {}
  ngOnInit() {
    // console.log(this.scrollContent);
    fromEvent(this.scrollContent, 'scroll')
      .pipe(takeUntil(this.destroy$))
      .subscribe((e: Event) => {
        const currentScroll = (e.target as Element).scrollTop;
        if (currentScroll > 100) {
          this.isScrolled = true;
        } else if (currentScroll < 10) {
          this.isScrolled = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next(null);
  }

  scrollToTop() {
    const smoothscroll = () => {
      const currentScroll = this.scrollContent.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        this.scrollContent.scrollTo(0, currentScroll - currentScroll / 8);
      }
    };
    smoothscroll();
  }

  getScrollHeight(e): number {
    e = (e.target as Element) || document.body;
    return Math.max(e.scrollHeight, e.offsetHeight, e.clientHeight);
  }
}
