import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstant } from '@common/services';
import { LocalStorageService } from 'ngx-webstorage';
import { BroadcastKey, Broadcaster } from './broadcaster.service';
// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Screens = { [key: string]: string };
export type Theme = 'theme-default' | string;
export type Themes = { id: string; name: string }[];
export type Layout = {
  navigation: 'none' | 'right' | 'left' | 'top' | 'none';
  navigationFolded: boolean;
  toolbar: 'above' | 'below' | 'none';
  footer: 'above' | 'below' | 'none';
  mode: 'boxed' | 'fullwidth';
};

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
  layout: Layout;
  scheme: Scheme;
  screens?: Screens;
  theme: Theme;
  themes?: Themes;
  colorClasses: {
    toolbar: string;
    navbar: string;
    footer: string;
  };
  customScrollbars: boolean;
  routerAnimation: 'fadeIn' | 'slideUp' | 'slideDown' | 'slideRight' | 'slideLeft' | 'none';
  appLogo?: string;
  tenantLogo?: string;
  language?: {
    path: string;
    default: string;
  };
}

/**
 * Default configuration for the entire application. This object is used by
 * FuseConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * FuseConfigService and its methods.
 *
 * "Screens" are carried over to the BreakpointObserver for accessing them within
 * components, and they are required.
 *
 * "Themes" are required for Tailwind to generate themes.
 */
export const defaultAppConfig: AppConfig = {
  layout: {
    navigation: 'left',
    navigationFolded: true,
    toolbar: 'below',
    footer: 'none',
    mode: 'fullwidth',
  },
  colorClasses: {
    toolbar: 'mat-white-500-bg',
    navbar: 'mat-fuse-dark-700-bg',
    footer: 'mat-fuse-dark-900-bg',
  },
  customScrollbars: true,
  routerAnimation: 'none',
  scheme: 'light',
  screens: {
    sm: '600px',
    md: '960px',
    lg: '1280px',
    xl: '1440px',
  },
  theme: 'theme-default',
  themes: [
    { id: 'theme-default', name: 'Default' },
    { id: 'theme-brand', name: 'Brand' },
    { id: 'theme-teal', name: 'Teal' },
    { id: 'theme-rose', name: 'Rose' },
    { id: 'theme-purple', name: 'Purple' },
    { id: 'theme-amber', name: 'Amber' },
  ],
  appLogo: 'assets/images/logos/nois-logo.png',
  tenantLogo: 'assets/images/logos/nois-logo.png',
  language: {
    path: '/assets/i18n/',
    default: 'vi',
  },
};

@Injectable()
export class AppConfigService {
  private _appConfig: AppConfig;

  constructor(private _http: HttpClient, private localStorageService: LocalStorageService, private _broadcaster: Broadcaster) {}

  public get appConfig(): AppConfig {
    this._appConfig = this.localStorageService.retrieve('tenantConfig') as AppConfig;

    if (!this._appConfig) {
      this._appConfig = defaultAppConfig;
    }
    return this._appConfig;
  }

  public set appConfig(data) {
    this._appConfig = data;
    this.localStorageService.store('tenantConfig', data);
  }

  public updateAppConfig(obj: any): void {
    this.appConfig = Object.assign(this._appConfig, obj);
  }

  public resetAppConfig(): void {
    this.localStorageService.clear('tenantConfig');
    this.appConfig = defaultAppConfig;
  }

  public getTenantAppConfig(tenantCode: string): void {
    this._http.get(`${AppConstant.domain}/api/tenants?code=${tenantCode}`).subscribe((res: any) => {
      const configure = res?.configure || {};
      const mappingTenantConfig: AppConfig = {
        layout: {
          navigation: configure.layoutNavigation || defaultAppConfig.layout.navigation,
          navigationFolded: configure.layoutNavigationFolded ?? defaultAppConfig.layout.navigationFolded,
          toolbar: configure.layoutToolbar || defaultAppConfig.layout.toolbar,
          footer: configure.layoutFooter || defaultAppConfig.layout.footer,
          mode: configure.layoutMode || defaultAppConfig.layout.mode,
        },
        scheme: configure.scheme || defaultAppConfig.scheme,
        theme: configure.theme || defaultAppConfig.theme,
        colorClasses: {
          toolbar: configure.classToolbar || defaultAppConfig.colorClasses.toolbar,
          navbar: configure.classNavbar || defaultAppConfig.colorClasses.navbar,
          footer: configure.classFooter || defaultAppConfig.colorClasses.footer,
        },
        language: {
          path: configure.languagePath || defaultAppConfig.language.path,
          default: configure.languageDefault || defaultAppConfig.language.default,
        },
        customScrollbars: configure.customScrollbars ?? defaultAppConfig.customScrollbars,
        routerAnimation: configure.routerAnimation || defaultAppConfig.routerAnimation,
        appLogo: configure.appLogo || defaultAppConfig.appLogo,
        tenantLogo: configure.tenantLogo || defaultAppConfig.tenantLogo,
      };
      this.appConfig = mappingTenantConfig;
      this._broadcaster.fire(BroadcastKey.APPCONFIG, this.appConfig);
    });
  }
}
