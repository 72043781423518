import { ErrorHandler, Injectable, isDevMode } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: any): void {
    try {
      const chunkFailedMessage = /Loading chunk [\d|\w]+ failed/;
      const message: string = error.message ? error.message : error.toString();
      if (chunkFailedMessage.test(message)) {
        if (confirm('Loading failed. Please refresh page?')) {
          window.location.reload();
        }
      }
      if (isDevMode()) {
        throw error;
      } else {
        console.error(message);
      }
    } catch (err) {
      console.error(err);
    }
  }
}
