import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../auth';

@Injectable()
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(private _authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = route.data && route.data.permission;
    if (!this._authService.checkPermission(permissions)) {
      return this.processPermission();
    }
    return true;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const permissions = route.data && route.data.permission;
    if (!this._authService.checkPermission(permissions)) {
      return this.processPermission();
    }
    return true;
  }

  public processPermission(): boolean {
    this.router.navigate(['auth', 'notpermission']);
    // if (this._authService.checkPermission(Permission.DASHBOARD)) {
    //   this.router.navigate(['pages']);
    // // } else if (this._authService.checkPermission(Permission.USER.READ)) {
    // //   this.router.navigate(['pages', 'profile']);
    // } else {
    //   this.fuseConfig.setConfig({
    //     layout: {
    //       navigation: 'none',
    //       toolbar: 'none',
    //       footer: 'none'
    //     }
    //   });
    //   this.router.navigate(['auth', 'login']);
    // }
    return false;
  }
}
