import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FuseNavHorizontalCollapseComponent } from './horizontal/nav-collapse/nav-horizontal-collapse.component';
import { FuseNavHorizontalItemComponent } from './horizontal/nav-item/nav-horizontal-item.component';
import { FuseNavigationComponent } from './navigation.component';
import { FuseNavVerticalCollapseComponent } from './vertical/nav-collapse/nav-vertical-collapse.component';
import { FuseNavVerticalGroupComponent } from './vertical/nav-group/nav-vertical-group.component';
import { FuseNavVerticalItemComponent } from './vertical/nav-item/nav-vertical-item.component';

@NgModule({
  imports: [CommonModule, RouterModule, MatIconModule, MatRippleModule, TranslateModule],
  exports: [FuseNavigationComponent],
  declarations: [
    FuseNavigationComponent,
    FuseNavVerticalGroupComponent,
    FuseNavVerticalItemComponent,
    FuseNavVerticalCollapseComponent,
    FuseNavHorizontalItemComponent,
    FuseNavHorizontalCollapseComponent,
  ],
})
export class FuseNavigationModule {}
