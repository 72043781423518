import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterByKeyValue' })
export class FilterByKeyValuePipe implements PipeTransform {
  public transform<T>(mainArr: T[], key: string, value: any): T[] {
    if (!key) {
      return mainArr;
    }
    return mainArr.filter((itemObj) => {
      if (typeof value === 'boolean') {
        return !!itemObj[key] === value;
      }
      return itemObj[key] === value;
    });
  }
}
