import { Component, OnInit } from '@angular/core';
import { AppConstant } from '@common/services';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss'],
})
export class ImageDialogComponent implements OnInit {
  public imageBase64: string;
  public imageUrl: string;
  public imageClass: string;
  public domain: string = AppConstant.domain + '/';

  constructor(public dialogRef: MatDialogRef<ImageDialogComponent>) {}

  ngOnInit() {
    if (this.imageUrl && this.imageUrl.indexOf('http') < 0) {
      this.imageUrl = this.domain + this.imageUrl;
    }
  }
}
