import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'htmlToPlaintext' })
export class HtmlToPlaintextPipe implements PipeTransform {
  public static t(value: string, args: any[] = []) {
    let result = value ? String(value).replace(/<\/?\w+((\s+\w+(\s*=\s*(?:".*?"|'.*?'|[\^'">\s]+))?)+\s*|\s*)\/?>/gm, '') : '';
    result = result.replace(/&nbsp;/g, ' ');
    result = unescape(result);
    const doc = new DOMParser().parseFromString(result, 'text/html');
    if (doc && doc.documentElement) {
      return doc.documentElement.textContent || result;
    }
    return result;
  }
  public transform(value: string, args: any[] = []) {
    return HtmlToPlaintextPipe.t(value, args);
  }
}
