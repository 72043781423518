import { NgModule } from '@angular/core';

import { NgxPowerBiComponent } from './ngx-powerbi.component';

@NgModule({
  imports: [],
  declarations: [NgxPowerBiComponent],
  exports: [NgxPowerBiComponent]
})
export class NgxPowerBiModule {}
