import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';

import { GenericListComponent } from './generic-list.component';
import { FuseSharedModule } from '@fuse/shared.module';
import { TranslateModule } from '@ngx-translate/core';
// import { ImageDialogComponent } from '@modules/generic-dialog/image-dialog/image-dialog.component';
import { FuseConfirmDialogModule } from '@fuse/components';
// import { GenericDialogModule } from '@modules/generic-dialog/generic-dialog.module';

@NgModule({
  declarations: [
    GenericListComponent,
  ],
  imports: [
    CommonModule, // ngTemplateOutlet
    FuseSharedModule,
    TranslateModule,
    FuseConfirmDialogModule,
    // GenericDialogModule,
  ],
  entryComponents: [
    // ImageDialogComponent
  ],
  exports: [
    GenericListComponent,
  ],
})
export class GenericListModule {
}
