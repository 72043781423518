import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { FuseDemoContentComponent } from './demo-content/demo-content.component';
import { FuseDemoSidenavComponent } from './demo-sidenav/demo-sidenav.component';

@NgModule({
  declarations: [FuseDemoContentComponent, FuseDemoSidenavComponent],
  imports: [RouterModule, MatDividerModule, MatListModule],
  exports: [FuseDemoContentComponent, FuseDemoSidenavComponent],
})
export class FuseDemoModule {}
