<ng-container *ngIf="layout === 'vertical'">
  <div class="navbar-vertical">
    <div class="navbar-header mat-fuse-dark-50-bg">
      <div class="logo cursor-pointer" (click)="redirectHomePage()">
        <img class="logo-icon app-slogan" />
      </div>

      <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarFolded('navbar')" fxHide.lt-lg>
        <mat-icon>menu</mat-icon>
      </button>

      <button mat-button class="toggle-button-navbar mat-icon-button" (click)="toggleSidebarOpened('navbar')" fxHide.gt-md>
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}">
      <fuse-navigation [navigation]="navigation" layout="vertical"></fuse-navigation>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layout === 'horizontal'">
  <div class="navbar-horizontal">
    <fuse-navigation [navigation]="navigation" layout="horizontal"></fuse-navigation>
  </div>
</ng-container>
