<mat-toolbar class="p-0 mat-elevation-z1">
  <mat-progress-bar *ngIf="showLoadingBar" class="loading-bar" color="accent" mode="indeterminate"></mat-progress-bar>

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button class="toggle-button-navbar mat-icon-button" *ngIf="!noNav" (click)="toggleSidebarOpened('navbar')" fxHide.gt-md>
        <mat-icon>menu</mat-icon>
      </button>

      <div class="toolbar-separator" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav || isSidebarFold">
        <div class="logo ml-20 cursor-pointer" (click)="redirectHomePage()">
          <img class="logo-icon app-slogan" />
        </div>
        <!--<div fxLayout="row" fxLayoutAlign="center center">-->
        <!--<mat-icon class="s-16" fxHide.xs>location_city</mat-icon>-->
        <!--<span class="username mr-12" style="font-size: 14px" fxHide fxShow.gt-sm>{{currentUser.plant}}</span>-->
        <!--</div>-->
      </div>

      <!-- <div class="ml-24 overflow-hidden" fxHide.xs fxLayout="row" *ngIf="plantName">
        <mat-icon class="s-30 m-4 mt-0">location_city</mat-icon>
        <span class="text-truncate">{{ plantName }}</span>
      </div> -->

      <!--<div class="px-8 px-mat-16">-->
      <!--<fuse-shortcuts></fuse-shortcuts>-->
      <!--</div>-->
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="end center">
      <!-- TODO: check end impersonate by another way -->
      <!-- <button mat-button class="user-button" (click)="endImpersonate()" *ngIf="permission.isEndImpersonate">
        <span>{{ 'COMMON.EndImpersonate' | translate }}</span>
      </button> -->

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img *ngIf="currentUser.avatar" class="avatar" [src]="currentUser.avatar" />
          <img *ngIf="!currentUser.avatar" class="avatar" src="assets/images/avatars/profile.jpg" />
          <span class="username mr-12" fxHide fxShow.gt-sm>{{
            (currentUser.fullName || '') + ' [' + (currentUser.username || currentUser.userName || currentUser.email) + ']'
          }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item (click)="profile()">
          <mat-icon>account_circle</mat-icon>
          <span>{{ 'COMMON.MYPROFILE' | translate }}</span>
        </button>

        <!--<button mat-menu-item class="">-->
        <!--<mat-icon>mail</mat-icon>-->
        <!--<span>Inbox</span>-->
        <!--</button>-->

        <button mat-menu-item class="" (click)="logout()">
          <mat-icon>exit_to_app</mat-icon>
          <span>{{ 'COMMON.LOGOUT' | translate }}</span>
        </button>
      </mat-menu>

      <div class="toolbar-separator" fxHide fxShow.gt-sm></div>
      <fuse-fullscreen fxHide fxShow.gt-sm></fuse-fullscreen>
      <div class="toolbar-separator"></div>

      <!--<fuse-search-bar (onInput)="search($event)"></fuse-search-bar>-->
      <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="notificationMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon [matBadge]="notificationBadge" matBadgeColor="warn">notifications</mat-icon>
        </div>
      </button>

      <mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" class="notification-menu">
        <div infiniteScroll (scrolled)="onNotiScrollDown()" [scrollWindow]="false" class="scroll-wrapper overflow-auto">
          <button mat-menu-item *ngFor="let n of notifications; let i = index" (click)="clickNotification(n)">
            <div fxLayout="row wrap" fxLayoutAlign="start center" class="notification">
              <div class="date mb-4" fxLayoutAlign="space-between end">
                <span>{{ n.createdOnDate | date : 'HH:mm dd/MM/yyyy' }}</span>
                <span>
                  <mat-icon class="mr-0" color="warn" *ngIf="!n.isSeen">new_releases</mat-icon>
                  <mat-icon class="mr-0 ml-8 cursor-pointer" (click)="$event.stopPropagation(); deleteNotification(n.id, i)">close</mat-icon>
                </span>
              </div>
              <div class="pre">
                <span class="indigo-900-fg">{{ n.title | translate }}: </span>
                <!-- <span class="indigo-900-fg">{{ n.entityName }} </span> -->
                <span [innerHTML]="n.description | translate : (n.params | stringToObject)"></span>
              </div>
            </div>
          </button>
        </div>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" width="16" [src]="domain + selectedLanguage?.flagImageFileName" *ngIf="selectedLanguage?.flagImageFileName" />
          <span class="iso text-uppercase">{{ selectedLanguage?.languageCulture?.toUpperCase() }}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <div fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" width="16" [src]="domain + lang.flagImageFileName" />
            <span class="iso">{{ lang.name || lang.languageCulture.toUpperCase() }}</span>
          </div>
        </button>
      </mat-menu>

      <!--<div class="toolbar-separator" fxHide fxShow.gt-xs></div>-->

      <!--<button mat-icon-button-->
      <!--class="mat-icon-button quick-panel-toggle-button"-->
      <!--fuseMatSidenavToggler="quick-panel"-->
      <!--aria-label="Toggle quick panel">-->
      <!--<mat-icon class="icon">format_list_bulleted</mat-icon>-->
      <!--</button>-->
    </div>
  </div>
</mat-toolbar>
