import { Injectable } from '@angular/core';
import { CustomReportType } from '@common/models/CommonConstant';
import { Broadcaster } from '@common/modules/share/broadcaster.service';
import { AuthService, CustomHttpClient } from '@common/services';
import { TranslateService } from '@ngx-translate/core';
import { FuseNavigationModel } from 'app/navigation/navigation';
import { Subject } from 'rxjs';

@Injectable()
export class FuseNavigationService {
  public flatNavigation: any[] = [];
  public navigationModel: any[] = [];

  public onItemCollapsed: Subject<any> = new Subject();
  public onItemCollapseToggled: Subject<any> = new Subject();

  constructor(private _http: CustomHttpClient, private _authService: AuthService, private translate: TranslateService, private _broadcaster: Broadcaster) {}

  /**
   * Get navigation model
   *
   * @returns {any[]}
   */
  public getNavigationModel() {
    return this.navigationModel || [];
  }

  /**
   * Set the navigation model
   *
   * @param updateMenu
   */
  public setNavigationModel(updateMenu: boolean = false) {
    this.navigationModel = new FuseNavigationModel(this._authService, this.translate).model;
    // this.navigationModel = model;
    if (updateMenu) {
      // this.updatePowerBiMenu();
    }
  }

  public updatePowerBiMenu() {
    const mLevel1Index = this.navigationModel.findIndex((o) => o.id === 'applications');
    const mLevel1 = this.navigationModel[mLevel1Index];
    const mLevel2 = mLevel1 && mLevel1.children && mLevel1.children.find((o) => o.id === 'reports');
    const mLevel3 = mLevel2 && mLevel2.children && mLevel2.children.find((o) => o.id === 'bi_report');
    if (this._authService.isAuthenticated) {
      if (mLevel2 && !mLevel2.hide) {
        this._http.Get('/menus?typeId=' + CustomReportType.ReportMenu).subscribe((resp: any) => {
          mLevel2.children = [
            ...resp.items
              .sort((a, b) => a.order - b.order)
              .map((item, index) => ({
                id: item.url,
                title: item.name,
                translate: item.name,
                type: 'item',
                url: item.url,
                hide: false,
              })),
            ...mLevel2.children,
          ];
          this.navigationModel[mLevel1Index] = { ...mLevel1 };
        });
      }
      if (mLevel3 && !mLevel3.hide) {
        this._http.Get('/menus?typeId=' + CustomReportType.PowerBI).subscribe((resp: any) => {
          // if (resp.items && resp.items.length) {
          //   this._broadcaster.fire('PowerBiMenu', resp.items);
          // }
          mLevel3.children = resp.items
            .sort((a, b) => a.order - b.order)
            .map((item, index) => ({
              id: item.reportId,
              title: item.name,
              translate: item.name,
              type: 'item',
              url: '/pages/dashboard/bi/' + item.reportId,
              hide: false,
            }));
          this.navigationModel[mLevel1Index] = { ...mLevel1 };
        });
      }
    }
  }

  /**
   * Get flattened navigation array
   * @param navigation
   * @returns {any[]}
   */
  public getFlatNavigation(navigation?) {
    // If navigation items not provided,
    // that means we are running the function
    // for the first time...
    if (!navigation) {
      // Reset the flat navigation
      this.flatNavigation = [];

      // Get the entire navigation model
      navigation = this.navigationModel || [];
    }
    for (const navItem of navigation) {
      if (navItem.type === 'item') {
        this.flatNavigation.push({
          title: navItem.title,
          type: navItem.type,
          icon: navItem.icon || false,
          url: navItem.url,
        });

        continue;
      }

      if (navItem.type === 'collapse' || navItem.type === 'group') {
        if (navItem.children) {
          this.getFlatNavigation(navItem.children);
        }
      }
    }

    return this.flatNavigation;
  }
}
