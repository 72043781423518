import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FilterKey } from '@common/models/CommonConstant';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { IApiListResponse } from '@services/http';
import { SessionStorageService } from 'ngx-webstorage';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { AppConstant } from '../../services';

export interface ListOptions {
  data?: any[];
  getListFn?: (param?: any) => Observable<IApiListResponse<any>>;
  listName: string;
  firstLoad?: boolean; // default true, generic list will load data after init component
  pagination?: boolean; // default true
  actionButtons?: string; // '' (not display) or 'button1', 'button2', 'button3' (class to fix action column width)
  extraButton?: { icon: string; name: string };
  rowClickable?: boolean; // default false
  rowDoubleClickable?: boolean; // default false
  showEdit?: boolean; // default false
  showDelete?: boolean; // default false
  filterData?: { [key: string]: any };
  sortIdDesc?: boolean; // default false, if true sort ID by desc
  showDisplayColumn?: boolean; // default false, UI for show/hide column
  rowHeight?: string;
  rowClass?: (row) => string;
  checkActiveRow?: string;
  columns: {
    name: string;
    childName?: string;
    pipeName?: string;
    sortName?: string; // if not define, use name as sortName
    className?: string; // class of <td>
    title: string; // display title, can input key to translate
    type?: 'text' | 'number' | 'translate' | 'date' | 'active' | 'image_sm' | 'link' | 'template' | 'select' | undefined;
    ordering?: boolean; // default false
    orderingDefault?: 'desc' | 'asc'; // default undefined or 'desc', 'asc'
    hide?: boolean; // default false
    filter?: boolean; // default false
    filterCondition?: string;
    filterValue?: string;
    template?: TemplateRef<any>;
    width?: string;
    footerColspan?: number;
    footerTemplate?: TemplateRef<any>;
  }[];
  showFooter?: boolean;
}

@Component({
  selector: 'generic-list',
  templateUrl: 'generic-list.component.html',
  styleUrls: ['generic-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericListComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() public options: ListOptions;
  @Input() public tableClass: string = '';
  @Input() public confirmMessage: string;
  @Input() public total: string;
  @Input() public dataBody: any;
  @Input() public pageSize: number = 20;
  public displayedColumns: string[] = [];
  public allColumns: any[] = [];
  public selectedRow = null;
  public dataSource = new MatTableDataSource();
  public pageIndex: number = 0;
  public resultsLength = 0;
  public isLoadingResults = false;
  public pipe = AppConstant.pipe;
  public domain = AppConstant.domain + '/';
  public forceDataChange: Subject<any> = new Subject();
  public dialogRef: any;
  public isSingleclick: boolean = true;
  public conditionList = [
    { id: 'Contains', name: 'Contains', type: 'text, translate, array' },
    { id: 'Equals', name: 'Equals', type: 'text, translate, number, date, datetime' },
    { id: 'Less than', name: 'Less than', type: 'number, date, datetime' },
    { id: 'Greater than', name: 'Greater than', type: 'number, date, datetime' },
    // { id: 'In range', name: 'In range', type: 'number, date, datetime' },
  ];
  public selection = new SelectionModel<any>(true, []);

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDataChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRowDbClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onExtraClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSelectionChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild(FusePerfectScrollbarDirective) private _fuseScrollbar: FusePerfectScrollbarDirective;

  private confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

  constructor(private sessionStorageService: SessionStorageService, private _cd: ChangeDetectorRef, private _dialog: MatDialog) {}

  ngOnInit() {
    this.buildListOptions();
    merge(this.sort.sortChange, this.forceDataChange)
      .pipe(
        takeUntil(this._onDestroy),
        switchMap((isResetPage) => {
          this.isLoadingResults = true;
          this._cd.markForCheck();
          if (isResetPage === true) {
            this.pageIndex = 0;
          }
          const params: any = {};
          if (this.options.pagination) {
            params.pageIndex = (this.pageIndex || 0) + 1;
            params.pageSize = this.pageSize || 20;
          }
          if (this.sort.active && this.sort.direction) {
            const column = this.options.columns.find((c) => c.name === this.sort.active);
            params.sortField = column ? (column.sortName ? column.sortName : column.name) : this.sort.active;
            params.orderDescending = this.sort.direction === 'desc' ? 'true' : 'false';
          } else if (this.options.sortIdDesc) {
            params.sortField = 'id';
            params.orderDescending = 'true';
          }
          for (const key of Object.keys(this.options.filterData)) {
            if (
              this.options.filterData[key] !== null &&
              this.options.filterData[key] !== undefined &&
              this.options.filterData[key] !== '' &&
              !Number.isNaN(this.options.filterData[key])
            ) {
              if (Array.isArray(this.options.filterData[key]) && this.options.filterData[key].length) {
                params[key] = this.options.filterData[key].join(',');
              } else {
                params[key] = this.options.filterData[key];
              }
            }
          }
          this.sessionStorageService.store(`${FilterKey.genericList.filterData}.${this.options.listName}`, params);
          if (this.options.getListFn) {
            // server side
            return this.options.getListFn(params);
          } else if (this.options.data) {
            // client side
            const obj = { items: [], totalData: 0, total: 0 };
            obj.items = [...this.options.data];
            // sort client
            if (params.sortField) {
              obj.items.sort((a, b) => {
                if (typeof a[params.sortField] === 'string' && typeof b[params.sortField] === 'string') {
                  const textA = a[params.sortField].toUpperCase();
                  const textB = b[params.sortField].toUpperCase();
                  if (params.orderDescending === 'true') {
                    return textA < textB ? -1 : textA > textB ? 1 : 0;
                  } else {
                    return textA < textB ? 1 : textA > textB ? -1 : 0;
                  }
                } else if (typeof a[params.sortField] === 'number' && typeof b[params.sortField] === 'number') {
                  if (params.orderDescending === 'true') {
                    return a[params.sortField] < b[params.sortField] ? -1 : a[params.sortField] > b[params.sortField] ? 1 : 0;
                  } else {
                    return a[params.sortField] < b[params.sortField] ? 1 : a[params.sortField] > b[params.sortField] ? -1 : 0;
                  }
                }
                return 0;
              });
            }
            // filter
            for (const key of Object.keys(params)) {
              obj.items = obj.items.filter((x) => {
                if (!x[key]) {
                  return true;
                }
                return x[key]?.toLowerCase().indexOf(params[key]?.toLowerCase()) >= 0;
              });
            }
            obj.totalData = obj.items.length;
            return of(obj);
          }
          return of({ items: [], totalData: 0, total: 0 });
        }),
        map((resp) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = this.options.pagination ? resp.totalData || resp.total || 0 : 0;
          this._fuseScrollbar?.scrollToTop();
          return resp.items || [];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        })
      )
      .subscribe((list) => {
        this.dataSource.data = list;
        this.onDataChanged.emit(list);
        this._cd.markForCheck();
      });
    if (this.options.firstLoad) {
      this.forceDataChange.next(true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes['options'].firstChange) {
      // console.log('change: ', changes['options']);
      this.buildListOptions();
    }
  }

  ngAfterViewInit() {
    if (this.options.pagination) {
      this.paginator.page.subscribe((e: PageEvent) => {
        this.pageIndex = e.pageIndex;
        this.pageSize = e.pageSize;
        this.forceDataChange.next(false);
      });
    }
    // If the user changes the sort order or search, reset back to the first page.
    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // this.forceDataChange.subscribe((isResetPage) => {
    //   if (isResetPage) {
    //     this.paginator.pageIndex = 0;
    //   }
    // });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public buildListOptions() {
    this.options = Object.assign(
      {
        getListFn: undefined,
        listName: '',
        pagination: true,
        showDisplayColumn: false,
        filterData: {},
        columns: [],
        firstLoad: true,
        showFooter: false,
      },
      this.options
    );
    const filterData = this.sessionStorageService.retrieve(`${FilterKey.genericList.filterData}.${this.options.listName}`);
    // TODO: check to get previous filter
    // if (this.options.filterData && filterData) {
    //   this.options.filterData = filterData;
    // }
    if (this.options.pagination) {
      this.pageSize = (filterData && filterData.pageSize) || 20;
    }
    // auto set action buttons
    if (!this.options.actionButtons) {
      const num = [this.options.showDelete, this.options.showEdit, this.options.extraButton].reduce((total, cur) => {
        if (cur) {
          total++;
        }
        return total;
      }, 0);
      if (num) {
        this.options.actionButtons = 'button' + num;
      }
    }
    this.displayedColumns = [];
    setTimeout(() => {
      if (this.options.actionButtons) {
        this.displayedColumns = this.options.columns
          .filter((c) => !c.hide)
          .map((c) => c.name)
          .concat(this.options.actionButtons);
        this.allColumns = [...this.options.columns, { name: this.options.actionButtons, title: 'COMMON.ACTION' }];
      } else {
        this.displayedColumns = this.options.columns.filter((c) => !c.hide).map((c) => c.name);
        this.allColumns = [...this.options.columns];
      }
      if (this.options.showDisplayColumn) {
        this.displayedColumns =
          this.sessionStorageService.retrieve(`${FilterKey.genericList.displayedColumns}.${this.options.listName}`) || this.allColumns.map((c) => c.name);
      }
      this.options.columns.forEach((c) => {
        if (!c.type) {
          c.type = 'text';
        }
        if (c.filter) {
          const condition = this.conditionList.find((o) => o.type.includes(c.type));
          c.filterCondition = condition ? condition.id : undefined;
        }
      });

      // find and set default sorting
      const sortingDefCol = this.options.columns.find((c) => !!c.orderingDefault);
      if (sortingDefCol) {
        this.sort.active = sortingDefCol.sortName || sortingDefCol.name;
        if (sortingDefCol.orderingDefault === 'desc') {
          this.sort.direction = 'desc';
        } else {
          this.sort.direction = 'asc';
        }
      }
      this._cd.markForCheck();
    });
  }

  public clickEdit(data) {
    this.onEdit.emit(data);
  }

  public clickDelete(id) {
    if (this.confirmMessage) {
      this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
        disableClose: false,
        autoFocus: false,
      });

      this.confirmDialogRef.componentInstance.confirmMessage = this.confirmMessage;

      this.confirmDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.onDelete.emit(id);
        }
        this.confirmDialogRef = null;
      });
    } else {
      this.onDelete.emit(id);
    }
  }

  public clickRow(data) {
    if (this.options.checkActiveRow && data[this.options.checkActiveRow] === false) {
      return;
    }
    this.isSingleclick = true;
    setTimeout(() => {
      if (this.isSingleclick) {
        if (!this.options.rowClickable) {
          return;
        } else {
          this.selectedRow = data;
          this.onRowClick.emit(data);
        }
      }
    }, 250);
    this.selection.toggle(data);
    this.selectionChange(this.selection.selected);
  }

  public dbClickRow(data) {
    this.isSingleclick = false;
    if (!this.options.rowDoubleClickable) {
      return;
    } else {
      this.onRowDbClick.emit(data);
    }
  }

  public clickLink(data) {
    this.onRowClick.emit(data);
  }

  public clickExtraButton(data) {
    if (this.options.extraButton) {
      this.onExtraClick.emit(data);
    }
  }

  public selectionChange(selected) {
    this.onSelectionChange.emit(selected);
  }

  public columnChange() {
    // console.log(this.displayedColumns);
    this.sessionStorageService.store(`${FilterKey.genericList.displayedColumns}.${this.options.listName}`, this.displayedColumns);
  }

  public filter(c) {
    this.options.filterData[c.name] = c.filterValue;
    this.options.filterData[c.name + 'Condition'] = c.filterCondition;
    console.log(c, this.options);
    this.forceDataChange.next(true);
  }

  public filterClosed(c) {
    c.filterValue = this.options.filterData[c.name];
    if (this.options.filterData[c.name + 'Condition']) {
      c.filterCondition = this.options.filterData[c.name + 'Condition'];
    }
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.options.checkActiveRow
      ? this.dataSource.data.filter((x) => x[this.options.checkActiveRow] === true).length
      : this.dataSource.data.length;
    return numSelected === numRows;
  }

  public toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectionChange([]);
      return;
    }
    if (this.options.checkActiveRow) {
      this.selection.select(...this.dataSource.data.filter((x) => x[this.options.checkActiveRow] === true));
    } else {
      this.selection.select(...this.dataSource.data);
    }
    this.selectionChange(this.selection.selected);
  }

  public toggleRow(row) {
    this.selection.toggle(row);
    this.selectionChange(this.selection.selected);
  }
}
