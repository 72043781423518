import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringToObject',
  pure: false,
})
export class stringToObjectPipe implements PipeTransform {
  public transform(value: string, separator = ','): Object {
    if (value && typeof value === 'string') {
      const newValue = value.split(separator);
      return Object.assign({}, newValue);
    }
    return value;
  }
}
