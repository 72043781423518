import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

export type TableSpans = {
  value: string;
  rowspan?: number;
  colspan?: number;
}[][];
@Injectable()
export class Util {
  constructor(private _translateService: TranslateService, private _toastrService: ToastrService) {}

  public getFullRoutePath(suffix, route: ActivatedRouteSnapshot) {
    if (route.routeConfig && route.routeConfig.path) {
      // If the path not empty
      suffix = `${route.routeConfig.path}/${suffix}`;
    }
    if (route.parent) {
      // If it still has parent
      return this.getFullRoutePath(suffix, route.parent);
    }
    return '/' + suffix;
  }

  public getFullRoutePathByActivatedRoute(suffix, route: ActivatedRoute) {
    if (route.routeConfig && route.routeConfig.path) {
      // If the path not empty
      suffix = `${route.routeConfig.path}/${suffix}`;
    }
    if (route.parent) {
      // If it still has parent
      return this.getFullRoutePathByActivatedRoute(suffix, route.parent);
    }
    return '/' + suffix;
  }

  public getLastActivatedRoute(route: ActivatedRoute) {
    while (route.firstChild) {
      route = route.firstChild;
    }

    return route;
  }

  /**
   * transformRequestHandler
   * @param obj
   * @returns {string}
   */
  public transformRequestHandler(obj): string {
    const str: string[] = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  public showError(error) {
    if (error.error) {
      this.showError(error.error);
    } else if (error.message) {
      this._toastrService.error(this._translateService.instant(error.message), 'Error', { timeOut: 10000 });
    } else if (error.error_description) {
      this._toastrService.error(this._translateService.instant(error.error_description), 'Error', { timeOut: 10000 });
    } else if (error.errorMessages && error.errorMessages instanceof Array) {
      let message = '';
      error.errorMessages.forEach((e) => {
        message += (e ? this._translateService.instant(e) : '') + '\n';
      });
      this._toastrService.error(message, 'Error', { timeOut: 10000 });
    } else if (typeof error === 'string') {
      this._toastrService.error(this._translateService.instant(error), 'Error', { timeOut: 10000 });
    } else {
      this._toastrService.error(this._translateService.instant('System error, please contact administrator for support'), 'Error', { timeOut: 10000 });
    }
  }

  public getFilename(url): string {
    if (!url) {
      return null;
    }
    return url.substring(url.lastIndexOf('/') + 1);
  }

  public downloadFile(fileUrl) {
    const filename = this.getFilename(fileUrl);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public downloadFileBase64(bytes: string, fileName: string) {
    var mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
    const link = document.createElement('a');
    link.href = mediaType + bytes;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public indexToAlphabet(n) {
    const a = 'A'.charCodeAt(0);
    const z = 'Z'.charCodeAt(0);
    const len = z - a + 1;
    let s = '';
    while (n >= 0) {
      s = String.fromCharCode((n % len) + a) + s;
      n = Math.floor(n / len) - 1;
    }
    return s;
  }

  /**
   * input value can be: 00:00, 1_:__, _2:34, __:56, ...
   * @param value
   * @returns {number}
   */
  public convertToMinute(value): number {
    value = value ? value.replace(/_/g, '0') : '';
    if (value === '') {
      return null;
    }
    const colonIndex = value.indexOf(':');
    let minute = null;
    if (colonIndex >= 0) {
      minute = +value.substr(0, colonIndex) + +value.substr(colonIndex + 1, 2) / 60;
    } else {
      minute = +value;
    }
    return minute;
  }

  public groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public calcYScaleMax(values, rate = 0.2) {
    const max = Math.max.apply(Math, [...values]);
    const min = Math.min.apply(Math, [...values]);
    const range = max - min;
    return max + Math.abs(range) * rate;
  }
  public calcYScaleMin(values, rate = 0.2) {
    const max = Math.max.apply(Math, [...values]);
    const min = Math.min.apply(Math, [...values]);
    const range = max - min;
    if (min >= 0) {
      if (min - Math.abs(range) * rate >= 0) {
        return min - Math.abs(range) * rate;
      } else {
        return 0;
      }
    } else {
      return min - Math.abs(range) * rate;
    }
  }

  public getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * function return random color, ex: "#019ABF"
   * See: http://martin.ankerl.com/2009/12/09/how-to-create-random-colors-programmatically/
   */
  public getRandomColor(length = 1, rgb?) {
    const GoldenRatioConjugate = 0.618033988749895;
    const hue2rgb = (p, q, t) => {
      if (t < 0) {
        t += 1;
      }
      if (t > 1) {
        t -= 1;
      }
      if (t < 1 / 6) {
        return p + (q - p) * 6 * t;
      }
      if (t < 1 / 2) {
        return q;
      }
      if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6;
      }
      return p;
    };
    const hslToRgb = (h, s, l) => {
      let r, g, b;
      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }
      if (rgb) {
        return Math.round(r * 255) + ', ' + Math.round(g * 255) + ', ' + Math.round(b * 255);
      }
      return '#' + Math.round(r * 255).toString(16) + Math.round(g * 255).toString(16) + Math.round(b * 255).toString(16);
    };

    const result = [];
    for (let i = 0; i < length; i++) {
      let hue = Math.random();
      hue += GoldenRatioConjugate;
      hue %= 1;
      result.push(hslToRgb(hue, 0.5, 0.6)); // default 0.5, 0.6
    }
    return result;
  }

  public padZero(str, len?) {
    len = len || 2;
    const zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }
  public invertColor(hex, bw = true) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error('Invalid HEX color.');
    }
    let r: number | string = parseInt(hex.slice(0, 2), 16),
      g: number | string = parseInt(hex.slice(2, 4), 16),
      b: number | string = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      // http://stackoverflow.com/a/3943023/112731
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return '#' + this.padZero(r) + this.padZero(g) + this.padZero(b);
  }
  public compareArray(arr1: (string | number)[] = [], arr2: (string | number)[] = []): boolean {
    if (arr1.sort().join(',') === arr2.sort().join(',')) {
      return true;
    }
    return false;
  }
  public compareObject(obj1, obj2): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  public createFileExport(response) {
    const contentDisposition = response.headers.headers.get('content-disposition')[0];
    let fileName = contentDisposition.substr(contentDisposition.indexOf('filename=') + 9);
    fileName = decodeURI(fileName.replace(/\"/g, ''));
    saveAs(response.body, fileName);
  }

  public getCookie(cname): string {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  public calcTableSpan(table: TableSpans): TableSpans {
    // calc colspan
    table.forEach((row) => {
      let firstCol = row[0];
      let prevCol;
      let colspan = 1;
      row.forEach((col) => {
        if (prevCol === col.value) {
          colspan += 1;
          col.colspan = 0;
        } else {
          // doesnt match, set colspan on first and reset colspan counter
          if (colspan > 1) {
            firstCol.colspan = colspan;
            colspan = 1;
          }
          firstCol = col;
          prevCol = col.value;
        }
      });
      if (colspan > 1) {
        firstCol.colspan = colspan;
        colspan = 1;
      }
    });

    // calc rowspan
    const numberOfColumn = table[0] && table[0].length;
    for (let i = 0; i < numberOfColumn; i++) {
      let firstRow = table[0][0];
      let prevRow;
      let rowspan = 1;
      table.forEach((row) => {
        if (row[i] && prevRow === row[i].value) {
          rowspan += 1;
          row[i].rowspan = 0;
        } else {
          // doesnt match, set rowspan on first and reset rowspan counter
          if (rowspan > 1) {
            firstRow.rowspan = rowspan;
            rowspan = 1;
          }
          firstRow = row[i];
          prevRow = row[i] && row[i].value;
        }
      });
      if (rowspan > 1) {
        firstRow.rowspan = rowspan;
        rowspan = 1;
      }
    }
    return table;
  }

  public getMaxValueInArray(arr: any[], key?: string) {
    let cloneArr = [...arr];
    cloneArr = cloneArr.sort((a, b) => {
      if (key && typeof b === 'object') {
        if (typeof b[key] === 'string') {
          return b[key]?.localeCompare(a[key]) || 0;
        } else if (typeof b[key] === 'number') {
          return b[key] - a[key];
        }
      } else if (!key) {
        if (typeof b === 'string') {
          return b?.localeCompare(a) || 0;
        } else if (typeof a === 'number') {
          return b - a;
        }
      }
      return 0;
    });
    if (cloneArr[0]) {
      if (key) {
        return cloneArr[0][key];
      } else {
        return cloneArr[0];
      }
    }
    return null;
  }
}
