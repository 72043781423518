import {
  Pipe,
  PipeTransform
} from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: any, format: string): any {
    return dayjs(value).format(format);
  }
}
