<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ 'COMMON.IMAGE' | translate }}</span>
      <button mat-button class="mat-icon-button" (click)="dialogRef.close(false)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div mat-dialog-content>
    <img [src]="'data:image/png;base64,' + imageBase64" [ngClass]="imageClass" *ngIf="imageBase64" />
    <img [src]="imageUrl" [ngClass]="imageClass" *ngIf="imageUrl" />
  </div>
  <div mat-dialog-actions fxLayoutAlign="end">
    <button mat-raised-button (click)="dialogRef.close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
  </div>
</div>
