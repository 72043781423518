import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDropDownItem } from '@common/models/common.model';
import { ControlType, EditorOptions } from '@common/models/CommonConstant';
import { CustomHttpClient } from '@common/services';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import 'tinymce/themes/silver';
import tinymce from 'tinymce/tinymce';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';

export type GenericDialogControl = {
  formControl?: FormControl;
  controlName: string;
  controlType?: ControlType;
  placeholder?: string;
  maxlength?: number;
  datasource?: IDropDownItem[];
  visible?: boolean;
  index?: number;
  cssClass?: string;
};
@Component({
  selector: 'generic-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [],
})
export class GenericDialogComponent implements OnInit, AfterViewInit {
  public title: string = '';
  public imgRef: MatDialogRef<ImageDialogComponent>;
  public controlType = ControlType;
  public data: any = { name: '' };
  public controls: GenericDialogControl[] = [];
  public frm: FormGroup;
  // public controlConfig = {};
  // public formErrors = {};
  // public validationMessages = {};
  public confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  public deleteOb: Observable<any>;
  public saveOb: Observable<any>;
  public saveFn: (any) => Observable<any>;
  public editorHeight = 300;
  public editorToolbar = 'bold italic underline fontsizes | forecolor backcolor | align | undo redo';
  public editorPlugins = '';
  public imagesUploadUrl = '';
  public editorOptions: any = Object.assign({}, EditorOptions);
  public hasEditor: boolean = false;
  public updatePermission: boolean = true;
  @ViewChild('submitBtn') public submitBtn: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    private _translateService: TranslateService,
    private _toastrService: ToastrService,
    private _http: CustomHttpClient,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    // super();
  }

  ngOnInit() {
    this.editorOptions.height = this.editorHeight;
    this.editorOptions.toolbar = this.editorToolbar;
    if (this.editorPlugins) {
      this.editorOptions.plugins = this.editorPlugins;
    }
    if (this.imagesUploadUrl) {
      this.editorOptions.images_upload_url = this.imagesUploadUrl;
      this.editorOptions.images_upload_handler = this._http.uploadHandler;
    }
    this.buildForm();
    // super.ngOnInit();
    if (this.data && this.data.id) {
      this.frm.patchValue(this.data);
    }
  }

  private buildForm(): void {
    this.frm = this._formBuilder.group({});
    this.controls = this.controls.map((c) => {
      const control: GenericDialogControl = Object.assign(
        {
          placeholder: c.controlName.toUpperCase(),
          controlType: ControlType.TEXTBOX,
          datasource: [],
          visible: true,
          index: 0,
          maxlength: null,
        },
        c
      );
      if (!control.formControl) {
        control.formControl = this._formBuilder.control(null);
      }
      this.frm.addControl(control.controlName, control.formControl);
      return control;
    });
    this.controls = [...this.controls.sort((a, b) => a.index - b.index)];
    // console.log(this.frm, this.controls);
  }

  // private initControl() {
  //   const defaultControls = [];
  //   Object.keys(this.data).forEach((key) => {
  //     if (key !== 'id') {
  //       const defaultControl = {
  //         controlName: key,
  //         controlType: this.controlType.TEXTBOX,
  //         placeholder: key.toUpperCase(),
  //         maxlength: null,
  //         requiredMessage: 'FIELD_IS_REQUIRED', // key.toUpperCase() + '_IS_REQUIRED',
  //         patternMessage: 'FIELD_INVALID_FORMAT', // key.toUpperCase() + '_IS_REQUIRED',
  //         datasource: [],
  //         disabled: false,
  //         visible: true,
  //         index: 0,
  //         validators: [Validators.required],
  //       };
  //       const control = Object.assign({}, defaultControl, this.controls.find((c) => c.controlName === key) || {});
  //       if (control.visible) {
  //         this.controlConfig[key] = new FormControl({ value: this.data[key], disabled: control.disabled }, control.validators);
  //         if (control.controlType === ControlType.HTML) {
  //           this.hasEditor = true;
  //         }
  //       }
  //       this.formErrors[key] = '';
  //       this.validationMessages[key] = {};
  //       if (control.requiredMessage) {
  //         this.validationMessages[key].required = this._translateService.instant(control.requiredMessage);
  //       }
  //       if (control.validators && control.patternMessage) {
  //         this.validationMessages[key].pattern = this._translateService.instant(control.patternMessage);
  //       }
  //       defaultControls.push(control);
  //     }
  //   });
  //   this.controls = defaultControls
  //     .filter((dc) => dc.visible)
  //     .slice(0)
  //     .sort((a, b) => a.index - b.index);
  // }

  ngAfterViewInit() {
    if (this.hasEditor) {
      tinymce.init(this.editorOptions);
    }
  }

  public save() {
    this.submitBtn.nativeElement.click();
    const data: any = Object.assign({}, this.data, this.frm.getRawValue());
    if (this.saveFn) {
      this.saveOb = this.saveFn(data).pipe(share());
      this.saveOb.subscribe((resp) => {
        this._toastrService.success(this._translateService.instant((resp && resp.message) || 'SUCCESS'));
        this.dialogRef.close(resp);
      });
    } else {
      this.dialogRef.close(data);
    }
  }

  public clickDelete() {
    if (!this.deleteOb) {
      return;
    }
    this.confirmDialogRef = this._dialog.open(FuseConfirmDialogComponent, {
      disableClose: true,
    });
    this.confirmDialogRef.componentInstance.confirmMessage = this._translateService.instant('CONFIRM_DELETE');
    this.confirmDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteOb.subscribe(
          (resp) => {
            this._toastrService.success(this._translateService.instant((resp && resp.message) || 'SUCCESS'));
            this.dialogRef.close(true);
          },
          (err) => {
            this.dialogRef.close(true);
          }
        );
      }
      this.confirmDialogRef = null;
    });
  }

  public handleInputChange(e, control) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    if (!file) {
      return;
    }
    const pattern = /image-*/;
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    const reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this, control);
    reader.readAsDataURL(file);
  }
  private _handleReaderLoaded(control, e) {
    const reader = e.target;
    const img = new Image();
    img.onload = this.resizeImage.bind(this, img, control);
    img.src = reader.result;
  }
  private resizeImage = (img, control) => {
    control.setValue(this.imageToDataUri(img, 480, 480));
    this.frm.markAsDirty();
  };
  private imageToDataUri(img, width, height) {
    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;
    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);
    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  public showImg(item) {
    this.imgRef = this._dialog.open(ImageDialogComponent, {
      disableClose: false,
    });
    this.imgRef.componentInstance.imageUrl = item;
  }
}
