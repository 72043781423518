import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Permission } from '@common/models/permission.model';
import { User } from '@common/models/users.model';
import { Broadcaster, BroadcastKey } from '@common/modules/share/broadcaster.service';
import { AppConstant } from '@common/services';
import { INotification, NotificationService } from '@common/services/business';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageModel } from '@pages/language/language.model';
import { LanguageService } from '@pages/language/language.service';
import { AuthService } from '@services/auth';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'fuse-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class FuseToolbarComponent implements OnDestroy {
  public userStatusOptions: any[];
  public languages: LanguageModel[] = [];
  public notifications: INotification[];
  public notificationBadge: number = 0;
  public selectedLanguage: LanguageModel;
  public showLoadingBar: boolean;
  public horizontalNav: boolean;
  public noNav: boolean;
  public currentUser: User = new User();
  public isSidebarFold: boolean = true;
  public permission: any = {};
  // public plantName: string = '';
  public domain: string = AppConstant.domain + '/';
  public notiPageIndex: number = 0;

  constructor(
    private router: Router,
    private fuseConfig: FuseConfigService,
    private sidebarService: FuseSidebarService,
    private translate: TranslateService,
    private _broadcaster: Broadcaster,
    private _notificationService: NotificationService,
    private _authService: AuthService,
    private localStorageService: LocalStorageService,
    private _languageService: LanguageService
  ) {
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];
    this.getLanguages();

    router.events.subscribe((event) => {
      setTimeout(() => {
        if (event instanceof NavigationStart) {
          this.showLoadingBar = true;
        }
        if (event instanceof NavigationEnd) {
          this.showLoadingBar = false;
        }
      });
    });

    this.fuseConfig.onConfigChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === 'top';
      this.noNav = settings.layout.navigation === 'none';
    });
    this.currentUser = this._authService.currentUser;
    // this.plantName = this.currentUser.plants
    //   ?.map((x) => x.name)
    //   .slice(0, 3)
    //   .join(', ');
    this.permission = {
      isEndImpersonate: this._authService.checkPermission(Permission.USER.IMPERSONATE),
    };
    this.sidebarService.onSidebarToggle.subscribe((value) => {
      this.isSidebarFold = value;
    });

    this._broadcaster.on(BroadcastKey.NOTIFICATION_DATA).subscribe((data: INotification[]) => {
      this.notifications = data;
      this.notificationBadge = this.notifications.filter((n) => !n.isSeen).length;
    });
    this._broadcaster.on(BroadcastKey.PROFILE).subscribe((data: any) => {
      this.currentUser.avatar = data;
    });
    this._broadcaster.on(BroadcastKey.UPDATE_LANGUAGE).subscribe(() => {
      this.getLanguages();
    });
  }

  private getLanguages() {
    // Khi cập nhật list language thì icon + code thay đổi theo
    this._languageService.getList({ isActive: true }).subscribe((res) => {
      this.languages = res.languageList;
      if (!this.languages?.length) {
        this.languages = [
          {
            displayOrder: 0,
            flagImageFileName: 'Resource/Flags/vn.png',
            id: 0,
            isActive: true,
            languageCulture: 'vi',
            name: 'Việt Nam',
          },
        ];
      }
      // Add languages
      this.translate.addLangs(this.languages.map((x) => x.languageCulture));
      // Lấy ngôn ngữ đang lưu ở localStorage để hiển thị
      // TODO: nếu không có ở localstorage thì lấy ngôn ngữ default của user
      const languageCulture = this.localStorageService.retrieve('lang') || this.languages[0].languageCulture || navigator.language.substring(0, 2);
      const language = this.languages.find((l) => l.languageCulture === languageCulture);
      this.selectedLanguage = language || this.languages[0];
      // set lại để hiện đúng translate
      this.setLanguage(this.selectedLanguage);
    });
  }

  public clickNotification(n) {
    console.log(n);
    if (!n.isSeen) {
      this._notificationService.updateNotificationStatus(n.id).subscribe(() => {
        n.isSeen = true;
        this.notificationBadge = this.notifications.filter((no) => !no.isSeen).length;
      });
    }

    //Redirect to page
    this.router.navigateByUrl(n.link);

    // switch (n.notification.entity) {
    //   case 'FormTemplate':
    //     this.router.navigate(['pages', 'formformat', 'forms', n.entityId]);
    //     break;
    //   case 'FormData':
    //     this.router.navigate(['pages', 'myform', 'detail', n.entityId]);
    //     break;
    //   case 'FormQCTemplate':
    //     this.router.navigate(['pages', 'formqc', 'detail/0/', n.entityId]);
    //     break;
    //   case 'FormQCData':
    //     this.router.navigate(['pages', 'formqc', 'detailData/1/', n.entityId]);
    //     break;
    //   case 'FormConfirm':
    //     this.router.navigate(['pages', 'formformat-confirm', 'comfirm', n.entityId]);
    //     break;
    //   case 'WorkOrderForm':
    //     this.router.navigate(['pages', 'operation', 'workOrderForms', n.entityId]);
    //     break;
    //   default:
    //     break;
    // }
  }

  public toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  public search(value) {
    // Do your search here...
    console.log(value);
  }

  public setLanguage(language: LanguageModel) {
    // Set the selected language for toolbar
    this.selectedLanguage = language;
    this.localStorageService.store('lang', language.languageCulture);
    // Use the selected language for translations
    this.translate.use(language.languageCulture);
  }

  public logout() {
    this.permission = {
      isEndImpersonate: this._authService.checkPermission(Permission.USER.IMPERSONATE),
    };
    // if (this.permission.isEndImpersonate) {
    //   this._notificationService.endImpersonate().subscribe((resp: any) => {});
    // }
    this._authService.logout(true);
  }

  public profile() {
    this.router.navigateByUrl('pages/profile');
  }

  public redirectHomePage() {
    this.router.navigateByUrl('pages/home');
  }

  ngOnDestroy() {}

  // public endImpersonate() {
  //   this._notificationService.endImpersonate().subscribe((resp: any) => {
  //     resp.username = resp.userName;
  //     this._authService.updateUserInfo(resp);
  //     this.permission = {
  //       isEndImpersonate: this._authService.checkPermission(Permission.USER.IMPERSONATE),
  //     };
  //     this.router.navigate(['pages']);
  //     window.location.reload();
  //   });
  // }

  public onNotiScrollDown() {
    this.notiPageIndex++;
    const params = {
      pageIndex: this.notiPageIndex + 1,
      pageSize: 20,
    };
    this._notificationService.getNotifications(params).subscribe({
      next: (resp) => {
        if (this.notiPageIndex > 0) {
          this.notifications = this.notifications.concat(resp);
        } else {
          this.notifications = resp;
        }
        this.notificationBadge = this.notifications.filter((n) => !n.isSeen).length;
      },
      error: () => {},
    });
  }
  public deleteNotification(id, index) {
    this._notificationService.deleteNotification(id).subscribe(() => {
      this.notifications.splice(index, 1);
      this.notificationBadge = this.notifications.filter((n) => !n.isSeen).length;
    });
  }
}
