import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'displayNameOption' })
export class DisplayNameMatOption implements PipeTransform {
  transform(item: { key: any }, disPlayName: string[] = []): string {
    return disPlayName
      .filter((i) => item.hasOwnProperty(i))
      .map((key) => `${item[key]}`)
      .join(' - ');
  }
}
