import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth';
import { Permission } from '@common/models/permission.model';

export class FuseNavigationModel {
  public model = [];

  constructor(private _authService: AuthService, private _translateService: TranslateService) {
    this.model = [
      {
        id: 'applications',
        title: this._translateService.instant('App'),
        translate: 'App',
        type: 'group',
        children: [
          {
            id: 'home',
            title: this._translateService.instant('Home'),
            translate: 'Home',
            type: 'item',
            icon: 'home',
            url: '/pages/home',
          },
          {
            id: 'dashboard',
            title: this._translateService.instant('Dashboard'),
            translate: 'Dashboard',
            type: 'collapse',
            icon: 'dashboard',
            hide: !this._authService.checkPermission([
              Permission.DASHBOARD.OVERVIEW,
              Permission.DASHBOARD.CONSUMPTION,
              Permission.DASHBOARD.PRODUCTIVITY,
              Permission.DASHBOARD.EFFECTIVE,
            ]),
            children: [
              {
                id: 'dashboard_overview',
                title: this._translateService.instant('NAV.DASHBOARD.OVERVIEW'),
                translate: 'NAV.DASHBOARD.OVERVIEW',
                type: 'item',
                url: '/pages/dashboard/overview',
                hide: !this._authService.checkPermission(Permission.DASHBOARD.OVERVIEW),
              },
              {
                id: 'dashboard_consumption',
                title: this._translateService.instant('NAV.DASHBOARD.CONSUMPTION'),
                translate: 'NAV.DASHBOARD.CONSUMPTION',
                type: 'item',
                url: '/pages/dashboard/consumption',
                hide: !this._authService.checkPermission(Permission.DASHBOARD.CONSUMPTION),
              },
              {
                id: 'dashboard_productivity',
                title: this._translateService.instant('NAV.DASHBOARD.PRODUCTIVITY'),
                translate: 'NAV.DASHBOARD.PRODUCTIVITY',
                type: 'item',
                url: '/pages/dashboard/productivity',
                hide: !this._authService.checkPermission(Permission.DASHBOARD.PRODUCTIVITY),
              },
              {
                id: 'dashboard_effective',
                title: this._translateService.instant('NAV.DASHBOARD.EFFECTIVE'),
                translate: 'NAV.DASHBOARD.EFFECTIVE',
                type: 'item',
                url: '/pages/dashboard/effective',
                hide: !this._authService.checkPermission(Permission.DASHBOARD.EFFECTIVE),
              },
            ],
          },
          {
            id: 'master_data',
            title: this._translateService.instant('NAV.MASTERDATA'),
            translate: 'NAV.MASTERDATA',
            type: 'collapse',
            icon: 'storage',
            hide: !this._authService.checkPermission([
              Permission.MASTERDATA.FACTORY,
              Permission.MASTERDATA.LINE,
              Permission.MASTERDATA.PRODUCT.VIEW,
              Permission.MASTERDATA.DEPARTMENT,
              Permission.MASTERDATA.DATASOURCE,
              Permission.MASTERDATA.GENERICATTRIBUTE,
              Permission.MASTERDATA.MATERIALTYPE.CREATE_EDIT_DELETE,
              Permission.MASTERDATA.MATERIALMANAGEMENT.CREATE_EDIT_DELETE,
              Permission.MASTERDATA.FINISHED_PRODUCT_TYPE.CREATE_EDIT_DELETE,
              Permission.MASTERDATA.STAGE.CREATE_EDIT_DELETE,
            ]),
            children: [
              {
                id: 'factory',
                title: this._translateService.instant('NAV.FACTORY'),
                translate: 'NAV.FACTORY',
                type: 'item',
                url: '/pages/factory',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.FACTORY) || !this._authService.isAdmin(),
              },
              {
                id: 'department',
                title: this._translateService.instant('NAV.DEPARTMENT'),
                translate: 'NAV.DEPARTMENT',
                type: 'item',
                url: '/pages/department',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.DEPARTMENT),
              },
              // {
              //   id: 'line',
              //   title: this._translateService.instant('NAV.LINE'),
              //   translate: 'NAV.LINE',
              //   type: 'item',
              //   url: '/pages/line',
              //   hide: !this._authService.checkPermission(Permission.MASTERDATA.LINE),
              // },
              {
                id: 'machine',
                title: this._translateService.instant('NAV.MACHINE'),
                translate: 'NAV.MACHINE',
                type: 'item',
                url: '/pages/machine',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.MACHINE),
              },
              {
                id: 'stage',
                title: this._translateService.instant('NAV.STAGE'),
                translate: 'NAV.STAGE',
                type: 'item',
                url: '/pages/stage',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.STAGE.CREATE_EDIT_DELETE),
              },
              {
                id: 'finished-product-type',
                title: this._translateService.instant('NAV.FINISHED.PRODUCT.TYPE'),
                translate: 'NAV.FINISHED.PRODUCT.TYPE',
                type: 'item',
                url: '/pages/finished-product-type',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.FINISHED_PRODUCT_TYPE.CREATE_EDIT_DELETE),
              },
              {
                id: 'product',
                title: this._translateService.instant('NAV.PRODUCT'),
                translate: 'NAV.PRODUCT',
                type: 'item',
                url: '/pages/product',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.PRODUCT.VIEW),
              },
              {
                id: 'material-type',
                title: this._translateService.instant('NAV.MATERIAL.TYPE'),
                translate: 'NAV.MATERIAL.TYPE',
                type: 'item',
                url: '/pages/material-type',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.MATERIALTYPE.CREATE_EDIT_DELETE),
              },
              {
                id: 'material-management',
                title: this._translateService.instant('NAV.MATERIAL'),
                translate: 'NAV.MATERIAL',
                type: 'item',
                url: '/pages/material-management',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.MATERIALMANAGEMENT.CREATE_EDIT_DELETE),
              },
              {
                id: 'machine-product-mapping',
                title: this._translateService.instant('COMMON.MACHINE.PRODUCT'),
                translate: 'COMMON.MACHINE.PRODUCT',
                type: 'item',
                url: '/pages/machine-product-mapping',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.MACHINEPRODUCT),
              },
              {
                id: 'datasource',
                title: this._translateService.instant('NAV.DATASOURCE'),
                translate: 'NAV.DATASOURCE',
                type: 'item',
                url: '/pages/datasource',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.DATASOURCE),
              },
              {
                id: 'masterdata_attribute',
                title: this._translateService.instant('NAV.MASTERDATA.ATTRIBUTE'),
                translate: 'NAV.MASTERDATA.ATTRIBUTE',
                type: 'item',
                url: '/pages/masterdata-attribute',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.GENERICATTRIBUTE),
              },
              {
                id: 'schedule-group',
                title: this._translateService.instant('NAV.SCHEDULE'),
                translate: 'NAV.SCHEDULE',
                type: 'item',
                url: '/pages/schedule-group',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.SCHEDULE),
              },
              {
                id: 'norms-group',
                title: this._translateService.instant('MASTERDATA.NORMS'),
                translate: 'MASTERDATA.NORMS',
                type: 'item',
                url: '/pages/norms-group',
                hide: !this._authService.checkPermission(Permission.MASTERDATA.NORMGROUP),
              },
              {
                id: 'inventory-product',
                title: this._translateService.instant('INVENTORY.PRODUCT.MANAGEMENT'),
                translate: 'INVENTORY.PRODUCT.MANAGEMENT',
                type: 'item',
                url: '/pages/inventory-product',
                // hide: !this._authService.checkPermission(Permission.MASTERDATA.INVENTORYPRODUCT),
              },
              {
                id: 'inventory-tracking',
                title: this._translateService.instant('RESIDUE.MANAGEMENT'),
                translate: 'RESIDUE.MANAGEMENT',
                type: 'item',
                url: '/pages/inventory-tracking',
                // hide: !this._authService.checkPermission(Permission.MASTERDATA.PRODUCTRESIDUE),
              },
            ],
          },
          {
            id: 'users',
            title: this._translateService.instant('NAV.USER.TITLE'),
            translate: 'NAV.USER.TITLE',
            type: 'collapse',
            icon: 'group',
            hide: !this._authService.checkPermission([Permission.USER.MANAGEMENT, Permission.USER.ROLE, Permission.USER.GROUP]),
            children: [
              {
                id: 'user',
                title: this._translateService.instant('NAV.USER.USERS'),
                translate: 'NAV.USER.USERS',
                type: 'item',
                url: '/pages/user-management/users',
                hide: !this._authService.checkPermission([Permission.USER.MANAGEMENT]),
              },
              {
                id: 'role',
                title: this._translateService.instant('NAV.USER.ROLES'),
                translate: 'NAV.USER.ROLES',
                type: 'item',
                url: '/pages/user-management/roles',
                hide: !this._authService.checkPermission([Permission.USER.ROLE]),
              },
              {
                id: 'userGroup',
                title: this._translateService.instant('NAV.USER.USERGROUPS'),
                translate: 'NAV.USER.USERGROUPS',
                type: 'item',
                url: '/pages/user-management/usergroups',
                hide: !this._authService.checkPermission([Permission.USER.GROUP]),
              },
            ],
          },
          {
            id: 'form',
            title: this._translateService.instant('NAV.FORMMANAGENMENT'),
            translate: 'NAV.FORMMANAGENMENT',
            type: 'collapse',
            icon: 'fact_check',
            hide: !this._authService.checkPermission([
              Permission.FORM.FORMTEMPLATEMANAGEMENT,
              Permission.FORM.FORMGROUPMANAGEMENT,
              Permission.FORM.APPROVEFORMTEMPLATE,
            ]),
            children: [
              {
                id: 'formformat',
                title: this._translateService.instant('NAV.FORM_TEMPLATE'),
                translate: 'NAV.FORM_TEMPLATE',
                type: 'item',
                url: '/pages/formformat/list',
                exactMatch: true,
                hide: !this._authService.checkPermission([Permission.FORM.FORMTEMPLATEMANAGEMENT]),
              },
              {
                id: 'formformat-confirm',
                title: this._translateService.instant('NAV.FORM_TEMPLATE_CONFIRM'),
                translate: 'NAV.FORM_TEMPLATE_CONFIRM',
                type: 'item',
                url: '/pages/formformat-confirm',
                hide: !this._authService.checkPermission([Permission.FORM.APPROVEFORMTEMPLATE]),
              },
              {
                id: 'formgroup',
                title: this._translateService.instant('NAV.FORMGROUP'),
                translate: 'NAV.FORMGROUP',
                type: 'item',
                url: '/pages/formgroup',
                hide: !this._authService.checkPermission([Permission.FORM.FORMGROUPMANAGEMENT]),
              },
            ],
          },
          {
            id: 'operation',
            title: this._translateService.instant('NAV.OPERATION'),
            translate: 'NAV.OPERATION',
            type: 'collapse',
            icon: 'tablet',
            hide: !this._authService.checkPermission([
              Permission.OPERATION.WORKORDER.VIEW,
              Permission.OPERATION.FORMDATA.VIEW,
              Permission.OPERATION.FORMDATA.VIEWALL,
              Permission.OPERATION.PROCESSFORMDATA.CREATE,
            ]),
            children: [
              {
                id: 'work-order',
                title: this._translateService.instant('NAV.PLAN.TITLE'),
                translate: 'NAV.PLAN.TITLE',
                // icon: 'library_books',
                hide: !this._authService.checkPermission([Permission.OPERATION.WORKORDER.VIEW]),
                type: 'item',
                url: '/pages/work-order',
              },
              {
                id: 'checklistFormat',
                title: this._translateService.instant('NAV.CREATE_FORM'),
                translate: 'NAV.CREATE_FORM',
                hide: !this._authService.checkPermission([Permission.OPERATION.PROCESSFORMDATA.CREATE]),
                type: 'item',
                url: '/pages/operation/checklistFormat',
              },
              {
                id: 'workOrderForms',
                title: this._translateService.instant('NAV.INPROGRESS_FORM'),
                translate: 'NAV.INPROGRESS_FORM',
                type: 'item',
                url: '/pages/operation/workOrderForms',
                hide: !this._authService.checkPermission([Permission.OPERATION.FORMDATA.VIEW]),
              },
              {
                id: 'myform',
                title: this._translateService.instant('NAV.MYFORM.TITLE'),
                translate: 'NAV.MYFORM.TITLE',
                type: 'item',
                url: '/pages/operation/myForms',
                hide: !this._authService.checkPermission([Permission.OPERATION.FORMDATA.VIEW]),
              },
              {
                id: 'allform',
                title: this._translateService.instant('NAV.ALLFORM.TITLE'),
                translate: 'NAV.ALLFORM.TITLE',
                type: 'item',
                url: '/pages/operation/allForms',
                hide: !this._authService.checkPermission([Permission.OPERATION.FORMDATA.VIEWALL]),
              },
            ],
          },
          {
            id: 'warningMachine',
            title: this._translateService.instant('WANING.MACHINE'),
            translate: 'WANING.MACHINE',
            type: 'item',
            url: '/pages/warning-machine',
            icon: 'info',
          },
          {
            id: 'mode-test',
            title: this._translateService.instant('MODE.TEST'),
            translate: 'MODE.TEST',
            type: 'item',
            url: '/pages/mode-test',
            icon: 'info',
          },
          {
            id: 'reports',
            title: this._translateService.instant('NAV.REPORT'),
            translate: 'NAV.REPORT',
            type: 'collapse',
            icon: 'insert_chart_outlined',
            hide: !this._authService.checkPermission([
              Permission.REPORT.VIEW_REPORT_PLANT_CDL,
              Permission.REPORT.VIEW_REPORT_PLANT_IN,
              Permission.REPORT.EXCELREPORT,
            ]),
            children: [
              {
                id: 'reports-plant-cdl',
                title: this._translateService.instant('REPORT.PLANT.CDL'),
                translate: 'REPORT.PLANT.CDL',
                type: 'collapse',
                hide: !this._authService.checkPermission([Permission.REPORT.VIEW_REPORT_PLANT_CDL]),
                children: [
                  {
                    id: 'quick_production',
                    title: this._translateService.instant('NAV.REPORT_QUICK_PRODUCTION'),
                    translate: 'NAV.REPORT_QUICK_PRODUCTION',
                    type: 'item',
                    url: '/pages/reports/quick-production',
                  },
                  {
                    id: 'finished_goods',
                    title: this._translateService.instant('NAV.REPORT_FINISHED_GOODS'),
                    translate: 'NAV.REPORT_FINISHED_GOODS',
                    type: 'item',
                    url: '/pages/reports/finished-goods',
                  },
                  {
                    id: 'monthly_consumption',
                    title: this._translateService.instant('NAV.REPORT_MONTHLY_CONSUMPTION'),
                    translate: 'NAV.REPORT_MONTHLY_CONSUMPTION',
                    type: 'item',
                    url: '/pages/reports/monthly-consumption',
                  },
                ],
              },
              {
                id: 'reports-plant-in',
                title: this._translateService.instant('REPORT.PLANT.IN'),
                translate: 'REPORT.PLANT.IN',
                type: 'collapse',
                hide: !this._authService.checkPermission([Permission.REPORT.VIEW_REPORT_PLANT_IN]),
                children: [
                  {
                    id: 'residual-tracking',
                    title: this._translateService.instant('NAV.REPORT_RESIDUAL_TRACKING'),
                    translate: 'NAV.REPORT_RESIDUAL_TRACKING',
                    type: 'item',
                    url: '/pages/reports/residual-tracking',
                  },
                  {
                    id: 'daily-product',
                    title: this._translateService.instant('NAV.REPORT_DAILY_PRODUCT'),
                    translate: 'NAV.REPORT_DAILY_PRODUCT',
                    type: 'item',
                    url: '/pages/reports/daily-product',
                  },
                  {
                    id: 'daily-norms-report',
                    title: this._translateService.instant('DAILY.NORM.REPORT'),
                    translate: 'DAILY.NORM.REPORT',
                    type: 'item',
                    url: '/pages/reports/daily-norms-report',
                  },
                  {
                    id: 'monthly-product',
                    title: this._translateService.instant('MONTHLY.REPORT'),
                    translate: 'MONTHLY.REPORT',
                    type: 'item',
                    url: '/pages/reports/monthly-product',
                  },
                ],
              },
              {
                id: 'bi_report',
                title: this._translateService.instant('NAV.BIREPORT'),
                translate: 'NAV.BIREPORT',
                type: 'collapse',
                hide: !this._authService.checkPermission([
                  Permission.REPORT.EXCELREPORT,
                  Permission.REPORT.VIEW_REPORT_PLANT_CDL,
                  Permission.REPORT.VIEW_REPORT_PLANT_IN,
                ]),
                children: [],
              },
              {
                id: 'report_master_data',
                title: this._translateService.instant('NAV.MASTERDATA'),
                translate: 'NAV.MASTERDATA',
                type: 'collapse',
                hide: !this._authService.checkPermission([
                  Permission.REPORT.EXCELREPORT,
                  Permission.REPORT.VIEW_REPORT_PLANT_CDL,
                  Permission.REPORT.VIEW_REPORT_PLANT_IN,
                ]),
                children: [
                  {
                    id: 'data.customreport',
                    title: this._translateService.instant('NAV.BIREPORT'),
                    translate: 'NAV.BIREPORT',
                    type: 'item',
                    url: '/pages/report-data/custom-report',
                  },
                ],
              },
            ],
          },
          {
            id: 'activityLog',
            title: this._translateService.instant('NAV.ACTIVITY_LOG'),
            translate: 'NAV.ACTIVITY_LOG',
            type: 'item',
            icon: 'history',
            url: '/pages/activity-log',
            hide: !this._authService.checkPermission([Permission.ACTIVITYLOG.VIEW]),
          },
          {
            id: 'configuration',
            title: this._translateService.instant('NAV.CONFIGURATION'),
            translate: 'NAV.CONFIGURATION',
            type: 'collapse',
            icon: 'settings',
            hide: !this._authService.checkPermission([
              Permission.CONFIGURATION.LANGUAGE,
              Permission.CONFIGURATION.SETTING,
              Permission.CONFIGURATION.CONTROLMAPPING,
              Permission.CONFIGURATION.NOTIFICATION.RECEIVEWORKORDER,
              Permission.CONFIGURATION.NOTIFICATION.OPERATIONWORKORDER,
            ]),
            children: [
              {
                id: 'setting',
                title: this._translateService.instant('NAV.SETTING.TITLE'),
                translate: 'NAV.SETTING.TITLE',
                type: 'item',
                url: '/pages/configs/setting',
                hide: !this._authService.checkPermission(Permission.CONFIGURATION.SETTING),
              },
              {
                id: 'language',
                title: this._translateService.instant('NAV.LANGUAGE.TITLE'),
                translate: 'NAV.LANGUAGE.TITLE',
                type: 'item',
                url: '/pages/configs/language',
                hide: !this._authService.checkPermission(Permission.CONFIGURATION.LANGUAGE),
              },
              {
                id: 'notification',
                title: this._translateService.instant('NAV.NOTIFICATION.TITLE'),
                translate: 'NAV.NOTIFICATION.TITLE',
                type: 'item',
                url: '/pages/configs/notification',
                hide: !this._authService.checkPermission([
                  Permission.CONFIGURATION.NOTIFICATION.RECEIVEWORKORDER,
                  Permission.CONFIGURATION.NOTIFICATION.OPERATIONWORKORDER,
                ]),
              },
              {
                id: 'controlMapping',
                title: this._translateService.instant('NAV.CONTROLMAPPING.TITLE'),
                translate: 'NAV.CONTROLMAPPING.TITLE',
                type: 'item',
                url: '/pages/configs/control-mapping',
                hide: !this._authService.checkPermission(Permission.CONFIGURATION.CONTROLMAPPING) || !this._authService.isAdmin(),
              },
            ],
          },
          {
            id: 'help',
            title: this._translateService.instant('NAV.HELP'),
            translate: 'NAV.HELP',
            type: 'collapse',
            icon: 'help_center',
            children: [
              {
                id: 'training',
                title: this._translateService.instant('NAV.HELP.TRAINING'),
                translate: 'NAV.HELP.TRAINING',
                type: 'item',
                url: '/pages/help/training',
              },
              // {
              //   id: 'postIssue',
              //   title: this._translateService.instant('NAV.HELP.ISSUE'),
              //   translate: 'NAV.HELP.ISSUE',
              //   type: 'item',
              //   url: '/pages/help/issue',
              // },
            ],
          },
        ],
      },
    ];
  }
}
