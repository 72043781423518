import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { AppConfigService } from '@common/modules/share/appConfig.service';

@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private translate: TranslateService, private localStorageService: LocalStorageService, private _appConfigService: AppConfigService) {
    const languageCulture =
      this.localStorageService.retrieve('lang') || this._appConfigService.appConfig.language.default || navigator.language.substring(0, 2);
    // Set the default language
    // this.translate.setTranslation(Language.VietNam.id, defaultLanguage); // this is fixed lang, if use it, cannot load lang form server
    this.translate.setDefaultLang(languageCulture);

    // Use a language
    this.translate.use(languageCulture);
  }
}
