import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toFixed',
})
export class ToFixedPipe implements PipeTransform {
  transform(value: any, args: any = { decimals: 2, lt: 1, gt: 1 }): any {
    if (+value) {
      args = Object.assign({ decimals: 2, lt: 1, gt: 1 }, args);
      if (args.lt && +value < args.lt) {
        return +(+value).toFixed(args.decimals);
      } else if (args.gt && +value > args.gt) {
        return +(+value).toFixed(args.decimals);
      } else if (args.lt === 2) {
        return Math.round(+value);
      } else {
        // return Math.round(+value);
        return +(+value).toFixed(3);
      }
    }
    return value;
  }
}
