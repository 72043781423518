import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { FuseMatSidenavHelperService } from '@fuse/directives/fuse-mat-sidenav/fuse-mat-sidenav.service';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { Subscription } from 'rxjs';


@Directive({
  selector: '[fuseMatSidenavHelper]',
})
export class FuseMatSidenavHelperDirective implements OnInit, OnDestroy {
  matchMediaSubscription: Subscription;
  @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
  @Input('fuseMatSidenavHelper') id: string;
  @Input('mat-is-locked-open') matIsLockedOpenBreakpoint: string = '';

  constructor(private fuseMatSidenavService: FuseMatSidenavHelperService, private fuseMatchMedia: FuseMatchMediaService, private observableMedia: MediaObserver, private matSidenav: MatSidenav) {}

  ngOnInit() {
    this.fuseMatSidenavService.setSidenav(this.id, this.matSidenav);

    if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
      this.isLockedOpen = true;
      this.matSidenav.mode = 'side';
      this.matSidenav.toggle(true);
    } else {
      this.isLockedOpen = false;
      this.matSidenav.mode = 'over';
      this.matSidenav.toggle(false);
    }

    this.matchMediaSubscription = this.fuseMatchMedia.onMediaChange.subscribe(() => {
      if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
        this.isLockedOpen = true;
        this.matSidenav.mode = 'side';
        this.matSidenav.toggle(true);
      } else {
        this.isLockedOpen = false;
        this.matSidenav.mode = 'over';
        this.matSidenav.toggle(false);
      }
    });
  }

  ngOnDestroy() {
    this.matchMediaSubscription.unsubscribe();
  }
}

@Directive({
  selector: '[fuseMatSidenavToggler]',
})
export class FuseMatSidenavTogglerDirective {
  @Input('fuseMatSidenavToggler') id;

  constructor(private fuseMatSidenavService: FuseMatSidenavHelperService) {}

  @HostListener('click')
  onClick() {
    this.fuseMatSidenavService.getSidenav(this.id).toggle();
  }
}
