import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DBConfig } from 'ngx-indexed-db';
// Ahead of time compiles requires an exported function for factories
// export function migrationFactory() {
//   // The animal table was added with version 2 but none of the existing tables or data needed
//   // to be modified so a migrator for that version is not included.
//   return {
//     2: (db, transaction) => {
//       const user = transaction.objectStore('user');
//       // user.createIndex('lastLogin', 'lastLogin', { unique: false });
//       const formControlValue = transaction.objectStore('formControlValue');
//       // formControlValue.createIndex('errorMessage', 'errorMessage', { unique: false });
//     }
//   };
// }
@Injectable()
export class AppConstant {
  public static domain = environment.domain;
  public static captchaSiteKey = '6LfGSKshAAAAAC0aCKwAKW6oczTWMI8IQTSO1sGY';
  public static clientId = 'a868cec2-5c40-4a90-b473-672f097e1695';
  public static clientSecret = '58ba131b-009e-4e77-98a1-9f570bd1dd89';
  public static displayOrderStep = 100;
  public static pattern = {
    email: '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$',
    phone: '(84|0[3|5|7|8|9])+([0-9]{8})',
    whiteSpace: '^\\S+(?: \\S+)*$',
    pin: '[0-9][0-9][0-9][0-9]',
    alphabet: '^[a-z A-Z]*$',
    ipAddress: '^(([01]?[0-9]?[0-9]|2[0-5][0-5])\\.){3}([01]?[0-9]?[0-9]|2[0-5][0-5])$',
    biggerZero: '^[1-9][0-9]*$',
    userName: '^[-_.@a-zA-Z0-9]*$',
    password: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$',
    datetime: '[\\d]{4}-[0-1][0-9]-[0-3][0-9]T[0-2][0-9]:[0-6][0-9]:[\\d]{2}',
    decimal: '\\d+(,\\d{3})*([.]\\d{1,3})?', // lấy 3 số thập phân
  };
  // use for angular date pipe
  public static pipe = {
    fullDate: 'dd/MM/yyyy',
    fullDateTime: 'dd/MM/yyyy HH:mm:ss',
    timeDate: 'HH:mm dd/MM/yyyy',
    dateTime: 'dd/MM/yyyy HH:mm',
    time: 'HH:mm:ss',
    date: 'dd/MM/yyyy',
    monthYear: 'MM/yyyy',
    year: 'yyyy',
    shortTime: 'HH:mm',
  };
  // format for moment or dayjs
  public static format = {
    fullDate: 'YYYY-MM-DD',
    fullDateTime: 'YYYY-MM-DDTHH:mm:ss', // this format use to save db
    longDateTime: 'DD/MMM/YYYY HH:mm:ss',
    timeDate: 'HH:mm DD/MM/YYYY',
    dateTime: 'DD/MM/YYYY HH:mm',
    time: 'HH:mm:ss',
    date: 'DD/MM/YYYY',
    shortTime: 'HH:mm',
    year: 'YYYY',
    month: 'M',
  };
  public static offlineConfig = {
    offlineMode: true,
    postRows: 10,
    timeSync: 60000,
    warningRows: 100,
  };

  public static dbConfig: DBConfig = {
    name: 'FasfDb',
    version: 1,
    objectStoresMeta: [
      {
        store: 'user',
        storeConfig: { keyPath: 'id', autoIncrement: false },
        storeSchema: [
          { name: 'accessToken', keypath: 'accessToken', options: { unique: false } },
          { name: 'refreshToken', keypath: 'refreshToken', options: { unique: false } },
          { name: 'lastLogin', keypath: 'lastLogin', options: { unique: false } },
        ],
      },
      {
        store: 'formControlValue',
        storeConfig: { keyPath: 'group', autoIncrement: false },
        storeSchema: [
          { name: 'values', keypath: 'values', options: { unique: false } },
          { name: 'userId', keypath: 'userId', options: { unique: false } },
          { name: 'tableId', keypath: 'tableId', options: { unique: false } },
          { name: 'errorMessage', keypath: 'errorMessage', options: { unique: false } },
        ],
      },
      {
        store: 'formControlValueSent',
        storeConfig: { keyPath: 'group', autoIncrement: false },
        storeSchema: [
          { name: 'values', keypath: 'values', options: { unique: false } },
          { name: 'userId', keypath: 'userId', options: { unique: false } },
          { name: 'tableId', keypath: 'tableId', options: { unique: false } },
          { name: 'formDataId', keypath: 'formDataId', options: { unique: false } },
          { name: 'errorMessage', keypath: 'errorMessage', options: { unique: false } },
        ],
      },
    ],
    // provide the migration factory to the DBConfig
    // migrationFactory
  };

  public static validation = {
    maxLength32: 32,
  };
}
