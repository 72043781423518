import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserToken } from '@common/models/UserToken';
import { ILoginForm } from '@common/models/common.model';
import { IUserRegister } from '@common/models/userRegister.model';
import { User } from '@common/models/users.model';
import { AppConfigService } from '@common/modules/share/appConfig.service';
import { Util } from '@services/util';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { AppConstant } from '../appConstant';

@Injectable()
export class AuthService {
  private _currentUser: User;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _util: Util,
    private localStorageService: LocalStorageService,
    private dbService: NgxIndexedDBService,
    private _appConfigService: AppConfigService
  ) {}

  public get currentUser(): User {
    this._currentUser = this.localStorageService.retrieve('userInfo') as User;

    if (!this._currentUser) {
      this._currentUser = new User();
    }
    return this._currentUser;
  }

  public set currentUser(info: User) {
    this._currentUser = info;
    this.localStorageService.store('userInfo', info);
  }

  public get isAuthenticated(): boolean {
    const { accessToken, refreshToken } = this.getToken();
    return !!accessToken && !!refreshToken && !!this.currentUser && !!this.currentUser.id; //if is first log in then not authenticate
  }

  /**
   * fillInfo
   * @param obj
   */
  public updateUserInfo(obj: any): void {
    this.currentUser = (Object as any).assign(this.currentUser, obj);
    // use broadcaster service if need
  }

  /**
   * clearInfo
   */
  public clear(): void {
    this.currentUser = new User();
    this.localStorageService.clear('userInfo');
    this.clearToken();
  }

  /**
   * Register for trial
   */
  public register(registerInfor: IUserRegister, tokencaptchar: string): Observable<any> {
    return this._http.post(`${AppConstant.domain}/api/auth/register?tokencaptchar=${tokencaptchar}`, registerInfor);
  }

  public login(form: ILoginForm): Observable<any> {
    if (!form || !form.username || !form.password) {
      return;
    }
    const data = {
      username: form.username,
      password: form.password,
      grant_type: 'password',
      client_id: AppConstant.clientId,
      client_secrect: AppConstant.clientSecret,
      requestVerificationToken: form.requestVerificationToken,
    };

    return this.postToken(data);
  }

  public getAntiforgerytoken() {
    return this._http.get<{ antiForgeryToken: string }>(`${AppConstant.domain}/api/security/antiforgerytoken`, { observe: 'response', withCredentials: true });
  }

  public postToken(data) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      // 'X-XSRF-TOKEN': data.requestVerificationToken,
    });
    return this._http.post(`${AppConstant.domain}/api/token`, this._util.transformRequestHandler(data), { headers }).pipe(
      tap((resp) => {
        this.processToken(resp);
      }),
      catchError((err) => {
        this.clear();
        return throwError(() => err);
      })
    );
  }

  public processToken(resp: any) {
    const userInfo = new User();
    userInfo.id = resp.userId;
    userInfo.userName = resp.userName;
    userInfo.userId = resp.userId;
    userInfo.userGuid = resp.userGuid;
    userInfo.fullName = resp.fullName;
    userInfo.permission = resp.permission;
    // userInfo.plant = resp.plant;
    // userInfo.departmentIds = resp.departmentIds ? resp.departmentIds.split(',') : [];
    // userInfo.plantIds = resp.plantIds ? resp.plantIds.split(',') : [];
    userInfo.isFirstLogin = resp.isFirstLogin === 'True' || resp.isFirstLogin === true;
    userInfo.isSso = resp.isSso === '1';
    userInfo.isSupperAdmin = resp.isSupperAdmin?.toLowerCase() == 'true';
    userInfo.timeoutExpired = resp.timeoutExpired;
    this.updateUserInfo(userInfo);
    this.setToken(resp.access_token, resp.refresh_token, resp.userId);
    // get user detail
    // this._http.get(`${AppConstant.domain}/api/users/Profile`).subscribe((user) => {
    //   this.updateUserInfo(new User(user));
    // });
    // const plantList = this.sessionStorageService.retrieve('plantList');
    // if (!plantList) {
    //   this._http.get<any>(`${AppConstant.domain}/api/plants/all`).subscribe(
    //     (res) => {
    //       this.sessionStorageService.store('plantList', res.plantList);
    //     },
    //     (err) => {
    //       this.sessionStorageService.clear('plantList');
    //     }
    //   );
    // }
  }

  //public signup(email: string, password: string, cb: any): void {}

  public logout(isRedirect = false): void {
    if (isRedirect && this.currentUser.isSso) {
      this.clear();
      // this._router.navigateByUrl('auth/notpermission');
      setTimeout(() => {
        window.location.href = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' + window.location.origin;
      });
    } else {
      this.clear();
      this._router.navigateByUrl('auth/login');
    }
  }

  public checkPermission(permissions: string | string[]) {
    if (!this.currentUser) {
      return false;
    }
    const userPermissions = (this.currentUser.permission || '').split(',');
    let permissionsArr = [];
    if (!permissions) {
      return true;
    } else if (typeof permissions === 'string') {
      permissionsArr = permissions.split(',');
    } else if (permissions instanceof Array) {
      permissionsArr = permissions;
    }
    // Find permission that user match
    return permissionsArr.some((p) => userPermissions.indexOf(p) >= 0);
  }

  public isAdmin(): boolean {
    return this.currentUser.isSupperAdmin;
  }

  public getToken() {
    let userToken = this.localStorageService.retrieve('userToken') as UserToken;

    if (!userToken) {
      userToken = new UserToken();
    }
    return userToken;
  }

  public setToken(access_token, refresh_token, userId?) {
    const userToken = new UserToken();
    userToken.accessToken = access_token;
    userToken.refreshToken = refresh_token;

    this.localStorageService.store('userToken', userToken);
    if (!userId) {
      userId = this.currentUser.id;
    }
    this.dbService.getByKey('user', userId).subscribe({
      next: (u) => {
        // console.log(u);
        if (u) {
          this.dbService.update('user', { accessToken: access_token, refreshToken: refresh_token, id: userId, lastLogin: Date.now() }).subscribe();
        } else {
          this.dbService.add('user', { accessToken: access_token, refreshToken: refresh_token, id: userId, lastLogin: Date.now() }).subscribe();
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
    const expiry = Date.now() - 7 * 24 * 60 * 60 * 1000; // 7 days
    this.dbService.getByIndex('user', 'lastLogin', [0, expiry]).subscribe({
      next: (u: any) => {
        if (u) {
          this.dbService.delete('user', u.id);
        }
      },
      error: (e) => console.log(e, 'auth.service setToken'),
    });
  }

  public clearToken() {
    this.localStorageService.clear('userToken');
  }
}
