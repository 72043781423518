import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements OnInit {
  private focus = true;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.focus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
        this.el.nativeElement.select();
      });
    }
  }

  @Input() public set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
