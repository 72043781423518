<mat-toolbar ngClass.gt-md="mx-20" fxHide.xs>
  <div fxLayout="row wrap" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
    <div fxFlex="1 1 auto" class="my-12">
      <img class="logo app-slogan" />
      <!-- <div class="ml-20">Copyright © 2021. All Rights Reserved by New Ocean IS</div> -->
    </div>
    <div fxFlex="0 1 auto" fxLayout.gt-xs="row wrap" fxLayout="column">
      <div class="mr-20" fxLayout="row">
        <mat-icon class="mr-8" fxHide.md>phone</mat-icon>
        <span
          >+84 28 6681 0782 (Office) <br />
          +84 985 868 872 (Sales Hotline)
        </span>
      </div>
      <div class="mr-20" fxLayout="row">
        <mat-icon class="mr-8" fxHide.md>mail</mat-icon>
        <span
          >info[@]nois[.]vn<br />
          sales[@]nois[.]vn</span
        >
      </div>
      <div fxLayout="row">
        <mat-icon class="mr-8" fxHide.md>domain</mat-icon>
        <span class="pre-line"
          >Headquarter: 4th Floor, 51 Hoang Viet, Tan Binh, Ho Chi Minh, VN <br />
          Branch: 10th Floor, 34 Hoang Viet, Tan Binh, Ho Chi Minh, VN<br />
          Branch: 5th Floor, 51 Hoang Viet, Tan Binh, Ho Chi Minh, VN
        </span>
      </div>
    </div>
  </div>
</mat-toolbar>
