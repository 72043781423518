import { Component, OnInit, ApplicationRef, OnDestroy } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval, Subscription } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  // title = 'app';
  private countIntervalSub: Subscription;
  private updatesAvailableSub: Subscription;
  constructor(private appRef: ApplicationRef, private swUpdate: SwUpdate, private http: HttpClient, private localStorageService: LocalStorageService) {
    setTimeout(() => {
      this.http.get('/version.json?t=' + Date.now()).subscribe({
        next: (resp: any) => {
          if (resp && resp.buildVersion) {
            this.localStorageService.store('ver', resp.buildVersion);
          }
        },
        error: (err) => console.log(err),
      });
    }, 1000);
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable) => isStable === true));
    const countInterval$ = interval(20 * 60 * 1000); // every 20 mins
    this.countIntervalSub = countInterval$.subscribe(() => {
      this.http.get('/version.json?t=' + Date.now()).subscribe((resp: any) => {
        const currentVer = this.localStorageService.retrieve('ver') || 0;
        if (resp && resp.buildVersion && currentVer < resp.buildVersion) {
          if (confirm('System updated. Please refresh page?')) {
            this.localStorageService.store('ver', resp.buildVersion);
            window.location.reload();
          }
        }
      });
    });
    const everyCountOnceAppIsStable$ = concat(appIsStable$, countInterval$);
    everyCountOnceAppIsStable$.subscribe(() => {
      if (this.swUpdate.isEnabled) {
        this.swUpdate.checkForUpdate();
      }
    });
  }
  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.updatesAvailableSub = this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          }))
        )
        .subscribe((event) => {
          console.log('sw update available', event);
          if (confirm('System updated. Please refresh page?')) {
            this.swUpdate.activateUpdate().then(() =>
              setTimeout(() => {
                window.location.reload();
              })
            );
          }
        });
      // this.swUpdate.activated.subscribe((event) => {
      //   console.log('sw update activated', event);
      // });
    }
  }

  ngOnDestroy() {
    if (this.countIntervalSub) {
      this.countIntervalSub.unsubscribe();
    }
    if (this.updatesAvailableSub) {
      this.updatesAvailableSub.unsubscribe();
    }
  }
}
