export const Permission = {
  DASHBOARD: {
    OVERVIEW: 'Dashboard.Overview',
    CONSUMPTION: 'Dashboard.Consumption',
    PRODUCTIVITY: 'Dashboard.Productivity',
    EFFECTIVE: 'Dashboard.Effective',
  },
  MASTERDATA: {
    LINE: 'MasterData.ProductionLine',
    PRODUCT: {
      VIEW: 'Product.View',
      CREATE_EDIT_DELETE: 'Product.CreateUpdateDelete',
      IMPORT: 'Product.Import',
    },
    MATERIALTYPE: {
      CREATE_EDIT_DELETE: 'MasterData.MaterialType',
    },
    MATERIALMANAGEMENT: {
      CREATE_EDIT_DELETE: 'MasterData.Material',
    },
    STAGE: {
      CREATE_EDIT_DELETE: 'MasterData.Stage',
    },
    FINISHED_PRODUCT_TYPE: {
      CREATE_EDIT_DELETE: 'MasterData.FinishProductType',
    },
    DEPARTMENT: 'MasterData.Department',
    DATASOURCE: 'MasterData.DataSource',
    GENERICATTRIBUTE: 'MasterData.GenericAttribute',
    FACTORY: 'MasterData.Factory',
    MACHINE: 'MasterData.Machine',
    MACHINEPRODUCT: 'MasterData.MachineProduct',
    SCHEDULE: 'MasterData.Schedule',
    NORMGROUP: 'MasterData.Norm',
    INVENTORYPRODUCT: 'MasterData.Inventory',
    PRODUCTRESIDUE: 'MasterData.Residule',
  },
  USER: {
    MANAGEMENT: 'User.UserManagement',
    IMPERSONATE: 'User.Impersonate',
    ROLE: 'User.UserRoleManagement',
    GROUP: 'User.UserGroupManagement',
  },
  FORM: {
    FORMTEMPLATEMANAGEMENT: 'Form.FormTemplateManagement',
    APPROVEFORMTEMPLATE: 'Form.ApproveFormTemplate',
    FORMGROUPMANAGEMENT: 'Form.FormGroupManagement',
  },
  OPERATION: {
    WORKORDER: {
      VIEW: 'WorkOrder.View',
      BLANK_CREATE: 'WorkOrder.Blank.Creation',
      BLANK_UPDATE: 'WorkOrder.Blank.Editing',
      NOT_BLANK_CREATE: 'WorkOrder.NotBlank.Creation',
      NOT_BLANK_UPDATE: 'WorkOrder.NotBlank.Editing',
      IMPORT: 'WorkOrder.Import',
      DELETE: 'WorkOrder.Delete',
      MACHINE_ALLOCATION: 'WorkOrder.Machine.Allocation',
      BLANK_ALLOCATION: 'WorkOrder.Blank.Allocation',
      WorkOrder_Complete: 'WorkOrder.Complete',
      WORKORDER_SYNC_ATTRIBUTEOFPRODUCT: 'WorkOrder.Sync.AttributeOfProduct',
    },
    FORMDATA: {
      VIEW: 'FormData.View',
      VIEWALL: 'FormData.ViewAllForm',
      FILTERMACHINE: 'FormData.FilterByMachine',
    },
    PROCESSFORMDATA: {
      COPY: 'ProcessFormData.Copy', //Copy Form data
      CREATE: 'ProcessFormData.CreateFormData', //Tạo Form data (không theo WO)
      EDIT: 'ProcessFormData.EditFormData', //Chỉnh sửa Form data
      DELETE: 'ProcessFormData.DeleteFormData', //Xóa Form data
      INPUT: 'ProcessFormData.InputFormData', //Nhập liệu Form data
      EDIT_DELETEROW: 'ProcessFormData.EditDeleteFormDataRow', //Chỉnh sửa, xóa một dòng dữ liệu trong bảng
      EXPORT: 'ProcessFormData.ExportExcelFormData', //Xuất file excel Form data
      VIEWREPORT: 'ProcessFormData.ViewReportFormData', //Xem báo cáo
      EDITAFTERCOMPLETED: 'ProcessFormData.EditFormDataAfterCompleted', // Cho phép sửa form data sau khi đã hoàn thành
    },
  },
  REPORT: {
    EXCELREPORT: 'Report.ExcelReport',
    VIEW_REPORT_PLANT_CDL: 'Report.FilterlessFactory.ViewReport',
    VIEW_REPORT_PLANT_IN: 'Report.PrintingFactory.ViewReport',
  },
  CONFIGURATION: {
    SETTING: 'Configuration.Setting',
    LANGUAGE: 'Configuration.Language',
    CONTROLMAPPING: 'Configuration.ControlMapping',
    CONNECTMACHINE: 'Configuration.Machine',
    NOTIFICATION: {
      RECEIVEWORKORDER: 'Notification.ReceiveWorkOrder',
      OPERATIONWORKORDER: 'Notification.OperationWorkOrder',
    },
  },
  ACTIVITYLOG: {
    VIEW: 'ActivityLog.View',
  },
};

export interface IPermission {
  category: string;
  description: string;
  id: number;
  name: string;
  order: number;
  systemName: string;
  children?: IPermission[];
}
