import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filterByIds', pure: false })
export class FilterByIdsPipe implements PipeTransform {
  public transform<T>(mainArr: T[], ids: number[]): T[] {
    if (!ids) {
      return mainArr;
    }
    return mainArr.filter((itemObj) => {
      return ids.includes(itemObj['id']);
    });
  }
}
