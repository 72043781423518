import { Component, Input, OnInit } from '@angular/core';
import { interval, map, Observable } from 'rxjs';
import dayjs from 'dayjs';
import 'dayjs/plugin/duration'

@Component({
    selector   : 'fuse-countdown',
    templateUrl: './countdown.component.html',
    styleUrls  : ['./countdown.component.scss']
})
export class FuseCountdownComponent implements OnInit
{
    @Input() eventDate;
    countdown: any;

    constructor()
    {
        this.countdown = {
            days   : '',
            hours  : '',
            minutes: '',
            seconds: ''
        };
    }

    ngOnInit()
    {
        const currDate = dayjs();
        const eventDate = dayjs(this.eventDate);

        let diff = eventDate.diff(currDate, 'seconds');

        const countDown =

                      interval(1000).pipe(
                      map((value) => diff = diff - 1),
                      map((value) => {
                          const timeLeft = dayjs.duration(value, 'seconds');

                          return {
                              days   : timeLeft.asDays().toFixed(0),
                              hours  : timeLeft.hours(),
                              minutes: timeLeft.minutes(),
                              seconds: timeLeft.seconds()
                          };
                      }));

        countDown.subscribe((value) => {
            this.countdown = value;
        });
    }
}
