import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  public transform(value: any, key?: string): any {
    if (Array.isArray(value)) {
      if (key) {
        return value.map((v) => v[key]).join(', ');
      } else {
        return value.join(', ');
      }
    } else {
      return value;
    }
  }
}
