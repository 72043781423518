import { Injectable } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FuseMatchMediaService {
  activeMediaQuery: string;
  onMediaChange: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private observableMedia: MediaObserver) {
    this.activeMediaQuery = '';

    this.observableMedia.asObservable().subscribe((change: MediaChange[]) => {
      if (change[0] && this.activeMediaQuery !== change[0].mqAlias) {
        this.activeMediaQuery = change[0].mqAlias;
        this.onMediaChange.next(change[0].mqAlias);
      }
    });
  }
}
