<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{ title || (data && data.id ? 'COMMON.UPDATE' : 'COMMON.ADDNEW') | translate }}</span>
      <button mat-button class="mat-icon-button" (click)="dialogRef.close(false)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div mat-dialog-content>
    <form name="frm" [formGroup]="frm" class="w-100-p" autocomplete="on" fxLayout="row wrap">
      <div *ngFor="let c of controls" class="w-100-p" [ngClass]="[c.cssClass || '', !c.visible ? 'hidden' : '']">
        <mat-form-field class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.TEXTBOX">
          <input
            matInput
            formControlName="{{ c.controlName }}"
            placeholder="{{ c.placeholder | translate }}"
            name="{{ c.controlName | spaceToDash }}"
            autocomplete="{{ c.controlName | spaceToDash }}"
            [maxlength]="c.maxlength"
          />
          <mat-error *ngIf="frm.get(c.controlName).hasError('required')">{{ 'FIELD_IS_REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="frm.get(c.controlName).hasError('pattern')">{{ 'FIELD.INCORRECT.FORMAT' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.NUMBER">
          <input type="number" matInput formControlName="{{ c.controlName }}" placeholder="{{ c.placeholder | translate }}" />
          <mat-error *ngIf="frm.get(c.controlName).hasError('required')">{{ 'FIELD_IS_REQUIRED' | translate }}</mat-error>
          <mat-error *ngIf="frm.get(c.controlName).hasError('min')">{{ 'FIELD.VALIDATE.MIN' | translate }}</mat-error>
        </mat-form-field>
        <div class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.HTML">
          <label>{{ c.placeholder | translate }}</label>
          <editor id="editorId" initialValue="" [init]="editorOptions" formControlName="{{ c.controlName }}" tagName="div"></editor>
        </div>

        <mat-form-field class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.TEXTAREA">
          <textarea matInput formControlName="{{ c.controlName }}" placeholder="{{ c.placeholder | translate }}" rows="2"></textarea>
          <mat-error *ngIf="frm.get(c.controlName).hasError('required')">{{ 'FIELD_IS_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
        <div class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.CHECKBOX">
          <div class="custom-form-field">
            <label>{{ c.placeholder | translate }}</label>
            <mat-checkbox formControlName="{{ c.controlName }}"></mat-checkbox>
          </div>
        </div>
        <div class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.RADIOBUTTON">
          <div class="custom-form-field">
            <label>{{ c.placeholder | translate }}</label>
            <mat-radio-group formControlName="{{ c.controlName }}">
              <mat-radio-button *ngFor="let d of c.datasource || []" [value]="d.id">{{ d.value ? d.value : d.name }} </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-form-field class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.COMBOBOX || c.controlType === controlType.MULTISELECT">
          <mat-select
            formControlName="{{ c.controlName }}"
            placeholder="{{ c.placeholder | translate }}"
            [multiple]="c.controlType === controlType.MULTISELECT"
          >
            <mat-option *ngFor="let d of c.datasource || []" [value]="d.id">{{ d.value ? d.value : d.name }} </mat-option>
          </mat-select>
          <mat-error *ngIf="frm.get(c.controlName).hasError('required')">{{ 'FIELD_IS_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.DATEPICKER || c.controlType === controlType.DATETIMEPICKER">
          <input
            matInput
            formControlName="{{ c.controlName }}"
            placeholder="{{ c.placeholder | translate }}"
            [readonly]="true"
            [selectMode]="'single'"
            [owlDateTime]="dt1"
            [owlDateTimeTrigger]="dt1"
          />
          <span [owlDateTimeTrigger]="dt1" matSuffix>
            <mat-icon>date_range</mat-icon>
          </span>
          <owl-date-time [pickerType]="c.controlType === controlType.DATEPICKER ? 'calendar' : 'both'" #dt1> </owl-date-time>
          <mat-error *ngIf="frm.get(c.controlName).hasError('required')">{{ 'FIELD_IS_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="w-100-p pl-8 pr-8 icon-click" *ngIf="c.controlType === controlType.YEARPICKER">
          <input [formControlName]="c.controlName" [owlDateTime]="picker" class="visibility-hidden position-absolute" />
          <input
            matInput
            [placeholder]="c.placeholder | translate"
            readonly
            [owlDateTimeTrigger]="picker"
            [value]="frm.get(c.controlName).value | date : 'yyyy'"
          />
          <mat-icon matSuffix class="font-size-22 mr-4" *ngIf="frm.get(c.controlName).value" (click)="frm.get(c.controlName).setValue(null)">close</mat-icon>
          <owl-date-time
            #picker
            [pickerType]="'calendar'"
            [startView]="'multi-years'"
            (yearSelected)="picker.close(); frm.get(c.controlName).setValue($event)"
            (monthSelected)="picker.close(); frm.get(c.controlName).setValue($event)"
          ></owl-date-time>
          <mat-error *ngIf="frm.get(c.controlName).hasError('required')">{{ 'FIELD_IS_REQUIRED' | translate }}</mat-error>
        </mat-form-field>
        <div class="w-100-p pl-8 pr-8" *ngIf="c.controlType === controlType.IMAGEPICKER">
          <div fxLayout="row wrap" fxLayoutAlign="start start" class="w-240">
            <!-- <div class='text-center w-100-p'>{{'COMMON.NOCODE' | translate}}</div> -->
            <div class="text-center w-100-p position-relative" *ngIf="frm.get(c.controlName).value">
              <mat-icon class="img-close-icon" (click)="frm.get(c.controlName).setValue(null); frm.markAsDirty()">cancel</mat-icon>
              <img class="avatar-lg" [src]="frm.get(c.controlName).value" (click)="showImg(frm.get(c.controlName).value)" />
            </div>
            <input
              #fileUpload
              hidden
              name="imageUrl"
              type="file"
              accept="image/png,image/jpeg,image/jpg,image/gif,image/bmp,image/svg"
              (change)="handleInputChange($event, frm.get(c.controlName))"
            />
            <button mat-raised-button class="mat-accent" (click)="fileUpload.click()" *ngIf="!frm.get(c.controlName).value">
              <span class="mr-8">{{ 'COMMON.UPLOAD' | translate }}</span>
              <mat-icon class="white-color">cloud_upload</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <input type="submit" fxHide #submitBtn />
    </form>
  </div>

  <div mat-dialog-actions fxLayoutAlign="space-between center">
    <div>
      <button mat-raised-button (click)="clickDelete()" class="mat-warn" *ngIf="deleteOb">{{ 'COMMON.DELETE' | translate }}</button>
    </div>
    <div>
      <button
        mat-raised-button
        class="mat-accent"
        (click)="save()"
        [disabled]="frm.invalid || (frm.pristine && data)"
        promise-btn
        [promiseButton]="saveOb"
        *ngIf="updatePermission"
      >
        {{ 'COMMON.SAVE' | translate }}
      </button>
      <button mat-raised-button (click)="dialogRef.close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
    </div>
  </div>
</div>
