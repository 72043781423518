import { Component, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';

import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { FuseNavigationModel } from 'app/navigation/navigation';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthService } from '@common/services';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'fuse-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FuseNavbarComponent implements OnDestroy {
  private fusePerfectScrollbar: FusePerfectScrollbarDirective;

  @ViewChild(FusePerfectScrollbarDirective, { static: true }) set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this.fusePerfectScrollbar = theDirective;

    this.navigationServiceWatcher = this.navigationService.onItemCollapseToggled.subscribe(() => {
      this.fusePerfectScrollbarUpdateTimeout = setTimeout(() => {
        this.fusePerfectScrollbar.update();
      }, 310);
    });
  }

  @Input() layout;
  navigation: any = [];
  navigationServiceWatcher: Subscription;
  fusePerfectScrollbarUpdateTimeout;

  constructor(
    private sidebarService: FuseSidebarService,
    private navigationService: FuseNavigationService,
    private translate: TranslateService,
    private _authService: AuthService,
    private _router: Router
  ) {
    // Navigation data
    this.navigationService.setNavigationModel(true);
    this.navigation = navigationService.getNavigationModel();
    // console.log('FuseNavbarComponent constructor GET NAVIGATION DATA ', this.navigation);
    // Default layout
    this.layout = 'vertical';
  }

  ngOnDestroy() {
    if (this.fusePerfectScrollbarUpdateTimeout) {
      clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
    }

    if (this.navigationServiceWatcher) {
      this.navigationServiceWatcher.unsubscribe();
    }
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  toggleSidebarFolded(key) {
    this.sidebarService.getSidebar(key).toggleFold();
  }

  redirectHomePage() {
    this._router.navigateByUrl('pages/home');
  }
}
