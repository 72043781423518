import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'leftByIndex' })
export class LeftByIndexPipe implements PipeTransform {
  transform(index: number, params = []) {
    // console.log(index, params);
    return params.reduce((a, b, i) => {
      if (i < index) {
        return a + b;
      }
      return a;
    }, 0);
  }
}
