import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { AppConstant, Util } from '@common/services';
import { ControlPropertyEnum, ControlType } from '@common/models/CommonConstant';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControlData } from '@common/models/formControl.model';

@Pipe({
  name: 'controlValue',
})
export class ControlValuePipe implements PipeTransform {
  DATETIME_REGEXP = new RegExp(AppConstant.pattern.datetime);
  constructor(private sanitized: DomSanitizer, private util: Util) {}
  public transform(
    values: any = [],
    params: { formControl: FormControlData; isTotal: boolean; rowIndex?: number; tableKey?: string; shiftTime?: string }
  ): any {
    params = params || { formControl: { control: {} }, isTotal: false };
    const formControl = params.formControl;
    if (formControl.control.name === ControlType.AUTOINDEX && (params.rowIndex || params.rowIndex === 0)) {
      const startIndex = formControl.formControlPropertyValues.find((fcp) => fcp.controlPropertyName === ControlPropertyEnum.STARTINDEX);
      const startIndexValue = startIndex?.value ? startIndex.value - 1 : 0;
      return params.rowIndex + startIndexValue;
    }
    const data = values.find((v) => v.formControlId === formControl.id);
    let result: any = data && data.value;
    if (result === null || result === undefined || Number.isNaN(result)) {
      result = '';
    }
    if (result !== '') {
      if (formControl.control.name === ControlType.NUMBER || formControl.control.name === ControlType.TIMEINPUT) {
        const originValue: string = result;
        let value: number = +originValue;
        if (formControl.control.name === ControlType.TIMEINPUT) {
          value = +this.util.convertToMinute(originValue);
        }
        if (Number.isFinite(value)) {
          const formula = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.FORMULA);
          if (formula && formula.value) {
            value = +value.toFixed(3);
          } else {
            value = +value;
          }
          if (formControl.control.name === ControlType.NUMBER) {
            result = value;
          } else {
            result = originValue;
          }
          let min = null;
          let max = null;
          if (params.isTotal) {
            min = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MINTOTAL);
            max = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MAXTOTAL);
          } else {
            min = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MIN);
            max = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MAX);
          }
          if (min && min.value !== '' && min.value !== null && min.value !== undefined && +value < +min.value) {
            let minColor = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MINCOLOR);
            minColor = (minColor && minColor.value) || '#f44336';
            result = `<div class='flex-center'>
                  <span style="color: ${minColor};">${result}</span>
                  <i class="material-icons ml-4" style="color: ${minColor};">trending_down</i></div>`;
          }
          if (max && max.value !== '' && max.value !== null && max.value !== undefined && +value > +max.value) {
            let maxColor = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MAXCOLOR);
            maxColor = (maxColor && maxColor.value) || '#f44336';
            result = `<div class='flex-center'>
                  <span style="color: ${maxColor};">${result}</span>
                  <i class="material-icons ml-4" style="color: ${maxColor};">trending_up</i></div>`;
          }
        }
      } else if (formControl.control.name === ControlType.DATEPICKER) {
        if (this.DATETIME_REGEXP.test(result) || result instanceof Date) {
          result = dayjs(result).format(AppConstant.format.date);
        }
      } else if (
        formControl.control.name === ControlType.DATETIMEPICKER ||
        formControl.control.name === ControlType.PICKTIMEBUTTON ||
        formControl.control.name === ControlType.AUTODATETIME
      ) {
        if (this.DATETIME_REGEXP.test(result) || result instanceof Date) {
          result = dayjs(result).format(AppConstant.format.dateTime);
        }
      } else if (formControl.control.name === ControlType.TIMEPICKER) {
        if (this.DATETIME_REGEXP.test(result) || result instanceof Date) {
          result = dayjs(result).format(AppConstant.format.shortTime);
        }
      } else if (formControl.control.name === ControlType.CHECKBOX || formControl.control.name === ControlType.MULTISELECT) {
        try {
          const value = JSON.parse(result);
          if (Array.isArray(value)) {
            result = value.filter((v) => v).join(', ');
          }
        } catch (e) {
          console.log(result, e);
        }
      } else if (formControl.control.name === ControlType.IMAGEPICKER) {
        try {
          const value = JSON.parse(result);
          if (Array.isArray(value)) {
            result = '';
            value.forEach((v) => {
              result += `<img src="${v}" width="40" height="40">`;
            });
          }
        } catch (e) {
          console.log(result, e);
        }
      }
      // HARDCODE FOR CATLOI Project
      if (params?.tableKey === 'KTCamQuan_VanHanh' && params?.shiftTime === 'Đêm') {
        if (formControl.name === 'ThoiGian') {
          const hour24 = +result + 12;
          result = hour24 > 24 ? hour24 - 24 : hour24;
        }
        if (formControl.name === 'GioKiemTra' && typeof result === 'string') {
          const currentTime = result.substring(0, result.indexOf(':'));
          if (currentTime) {
            const time24 = +currentTime + 12;
            result = result.replace(currentTime, (time24 > 24 ? time24 - 24 : time24) + '');
          }
        }
      }
    }
    if (data?.style) {
      result = `<div class="${data.style}">${result}</div>`;
    }
    return this.sanitized.bypassSecurityTrustHtml(result);
  }
}
