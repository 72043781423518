import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseToolbarComponent } from 'app/main/toolbar/toolbar.component';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationService } from '@common/services/business';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatChipsModule } from '@angular/material/chips';
import { MatBadgeModule } from '@angular/material/badge';
import { LanguageService } from '@pages/language/language.service';
import { FuseFullscreenModule } from '@common/modules/fullscreen';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [FuseToolbarComponent],
  imports: [
    RouterModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    MatToolbarModule,
    MatBadgeModule,
    MatChipsModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseFullscreenModule,
    InfiniteScrollModule,
  ],
  exports: [FuseToolbarComponent],
  providers: [NotificationService, LanguageService],
})
export class FuseToolbarModule {}
