import { Pipe, PipeTransform } from '@angular/core';
import { ControlPropertyEnum, ControlType } from '@common/models/CommonConstant';
import { Util } from '@common/services';

@Pipe({
  name: 'validateNumberColor',
})
export class ValidateNumberColorPipe implements PipeTransform {
  constructor(private util: Util) {}
  transform(value: any, formControl: any): any {
    const min = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MIN);
    const max = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MAX);
    if (formControl.control.name === ControlType.NUMBER) {
      value = +value;
    } else if (formControl.control.name === ControlType.TIMEINPUT) {
      value = this.util.convertToMinute(value);
    }
    if (value !== '' && value !== null && value !== undefined && value !== NaN) {
      if (min && min.value !== '' && min.value !== null && min.value !== undefined && +value < +min.value) {
        const minColor = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MINCOLOR);
        return (minColor && minColor.value) || '#f44336';
      }
      if (max && max.value !== '' && max.value !== null && max.value !== undefined && +value > +max.value) {
        const maxColor = formControl.formControlPropertyValues.find((fcpv) => fcpv.controlPropertyName === ControlPropertyEnum.MAXCOLOR);
        return (maxColor && maxColor.value) || '#f44336';
      }
    }
    return '';
  }
}
