import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { appRoutes } from './routes';
import { PageModule } from './pages';
import { AppComponent } from './app.component';
import { AppSharedModule } from './common';
import { AppModule as FuseAppModule } from 'app/app.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ShareModule } from '@modules/share/share.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { buildInfo } from '../environments/buildInfo';
import version from '../version.json';
import { AppConstant } from '@common/services';
import { GlobalErrorHandler } from './globalErrorHandler';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  const url = '/assets/i18n/';
  return new TranslateHttpLoader(http, url, '.json?ver=' + version.buildVersion);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FuseAppModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppSharedModule,
    RouterModule.forRoot(appRoutes, { useHash: false }),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
    }),
    NgxWebstorageModule.forRoot({
      prefix: 'nois',
      separator: '.',
      caseSensitive: false,
    }),
    PageModule,
    ShareModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production, scope: '/', registrationStrategy: 'registerImmediately' }),
    NgxIndexedDBModule.forRoot(AppConstant.dbConfig),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
