import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getByIndex',
})
export class GetByIndexPipe implements PipeTransform {
  public transform(array: any[] | string, index: number): any {
    if (!array || !Array.isArray(array) || !array.length) {
      return null;
    }
    return array[index];
  }
}
