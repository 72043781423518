import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fuse-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class FuseConfirmDialogComponent {
  public title: string;
  public confirmMessage: string;
  public removeButtonCancel: boolean;

  constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) {}
}
