import { NgModule } from '@angular/core';
import { MY_OWL_FORMATS } from '@common/models/CommonConstant';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from './src/public_api';

@NgModule({
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  exports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'vi'},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_OWL_FORMATS},
    // {provide: OwlDateTimeIntl, useClass: DefaultIntl},
  ]
})
export class NgPickDatetimeModule {
}
