import { Pipe, PipeTransform } from '@angular/core';
import { FormNameColors } from '@common/models/CommonConstant';

@Pipe({ name: 'formNameColor' })
export class FormDataColor implements PipeTransform {
  transform(formName: string, formType = FormNameColors): string {
    const formNameColor: any = formType.find((x: any) => formName.toLowerCase().includes(x.name.toLowerCase()));
    return formNameColor?.color || '';
  }
}
