import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';

import { fuseConfig } from './fuse-config';

import { AppComponent } from './app.component';
import { FuseMainModule } from './main/main.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    TranslateModule,

    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseMainModule,
  ],
  exports: [AppComponent, FuseModule, FuseMainModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
