import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UserIdleService } from '@modules/user-idle';
import { AuthService } from '@services/auth';
import { ISignalRConnection } from 'ng2-signalr';
import { NotificationService } from '@common/services/business';
import { ConnectionResolver } from '@common/modules/share/signalr.service';
import { Broadcaster, BroadcastKey } from '@common/modules/share/broadcaster.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'pages',
  template: '<router-outlet></router-outlet>',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, OnDestroy {
  public title = 'pages';
  protected _onDestroy = new Subject<void>();
  private _connection: ISignalRConnection;

  constructor(
    private _authService: AuthService,
    private _connectionResolver: ConnectionResolver,
    private _broadcaster: Broadcaster,
    private _notificationService: NotificationService,
    private fuseConfig: FuseConfigService,
    private _userIdle: UserIdleService
  ) {}

  ngOnInit() {
    const currentUser = this._authService.currentUser;
    // Start watching for user inactivity.
    this._userIdle.startWatching();

    // Start watching when user idle is starting.
    this._userIdle
      .onTimerStart()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((count) => console.log(count));

    // Start watch when time is up.
    this._userIdle
      .onTimeout()
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this._authService.logout(true));

    // SignalR
    this._connectionResolver
      .resolve()
      .then((c) => {
        this._connection = c;
        this._connection.invoke('JoinGroup', currentUser.id);
        this._connection.listenFor(BroadcastKey.NOTIFICATIONS).subscribe((data: any) => {
          console.log('data', data);
          this._broadcaster.fire(BroadcastKey.NOTIFICATIONS, data);
        });
      })
      .catch((e) => {
        console.warn(e);
      });

    this.getNotifications();
    this._broadcaster
      .on(BroadcastKey.NOTIFICATIONS)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        if (data) {
          this.getNotifications();
        }
      });
    setTimeout(() => {
      console.log('set layout config page component');
      this.fuseConfig.resetToAppConfig();
    });
    // this._plantSub = this._plantService.getListAll().subscribe();

    // Check Timeout Expired
    if (+currentUser.timeoutExpired) {
      this._userIdle.setConfigValue(+currentUser.timeoutExpired * 60);
    } else {
      this._userIdle.stopWatching();
    }
    this._broadcaster
      .on(BroadcastKey.APPCONFIG)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.fuseConfig.resetToAppConfig();
      });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._userIdle.stopWatching();
    if (this._connection) {
      this._connection.stop();
    }
  }

  private getNotifications() {
    this._notificationService
      .getNotifications()
      .pipe(takeUntil(this._onDestroy))
      .subscribe((resp) => {
        this._broadcaster.fire(BroadcastKey.NOTIFICATION_DATA, resp);
      });
  }
}
