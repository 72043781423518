import { Pipe, PipeTransform } from '@angular/core';
import { FormDataStatus } from '@common/models/CommonConstant';

@Pipe({ name: 'statusColor' })
export class StatusColorPipe implements PipeTransform {
  // formStatus = Object.values(FormStatus);
  transform(id, formStatus?): string {
    if (!formStatus) {
      formStatus = FormDataStatus;
    }
    const fs = Object.values(formStatus);
    const status: any = fs.find((s: any) => s.id === +id || s.code === id);
    return status ? status.color : '#039be5';
  }
}
