import { NgModule } from '@angular/core';
import { ImageDialogComponent } from '@modules/generic-dialog/image-dialog/image-dialog.component';
import { GenericDialogComponent } from '@modules/generic-dialog/dialog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUploaderModule } from 'ngx-uploader';
import { FuseSharedModule } from '@fuse/shared.module';
import { CommonPipesModule } from '@common/pipes/pipes.module';
import { PromiseButtonModule } from '../promise-button/promise-button.module';
import { OwlDateTimeModule } from '../ng-pick-datetime/src/public_api';

@NgModule({
  declarations: [GenericDialogComponent, ImageDialogComponent],
  imports: [FuseSharedModule, TranslateModule, EditorModule, NgxUploaderModule, CommonPipesModule, PromiseButtonModule, OwlDateTimeModule],
  entryComponents: [GenericDialogComponent, ImageDialogComponent],
  exports: [GenericDialogComponent, ImageDialogComponent],
})
export class GenericDialogModule {}
