import { NgModule } from '@angular/core';
import { ControlValuePipe, FormatTimePipe, StatusColorPipe, ValidateNumberColorPipe, SafeHtmlPipe } from '@common/pipes';
import { JoinPipe } from './join.pipe';
import { SafeStylePipe } from './safeStyle.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { SpaceToDashPipe } from './spaceToDash.pipe';
import { LeftByIndexPipe } from './leftByIndex.pipe';
import { ToFixedPipe } from './toFixed.pipe';
import { D3FormatPipe } from './d3Format.pipe';
import { StatusNamePipe } from './status-name.pipe';
import { GetByIndexPipe } from './getByIndex.pipe';
import { FormDataColor } from './form-name-color.pipe';
import { DisplayNameMatOption } from './displayNameMatOption.pipe';

@NgModule({
  declarations: [
    FormatTimePipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    StatusColorPipe,
    StatusNamePipe,
    ControlValuePipe,
    ValidateNumberColorPipe,
    JoinPipe,
    SpaceToDashPipe,
    LeftByIndexPipe,
    ToFixedPipe,
    D3FormatPipe,
    GetByIndexPipe,
    FormDataColor,
    DisplayNameMatOption,
  ],
  exports: [
    FormatTimePipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,
    StatusColorPipe,
    StatusNamePipe,
    ControlValuePipe,
    ValidateNumberColorPipe,
    JoinPipe,
    SpaceToDashPipe,
    LeftByIndexPipe,
    ToFixedPipe,
    D3FormatPipe,
    GetByIndexPipe,
    FormDataColor,
    DisplayNameMatOption,
  ],
})
export class CommonPipesModule {}
