import { Pipe, PipeTransform } from '@angular/core';
import * as d3Format from 'd3-format';

@Pipe({
  name: 'd3Format',
})
export class D3FormatPipe implements PipeTransform {
  transform(value: any, format: string): any {
    if (value && format) {
      return d3Format.format(format)(value);
    }
    return value;
  }
}
