import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseShortcutsComponent } from './shortcuts.component';
// import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [FuseShortcutsComponent],
  imports: [CommonModule, RouterModule, FlexLayoutModule, MatButtonModule, MatDividerModule, MatFormFieldModule, MatIconModule, MatInputModule, MatMenuModule, MatListModule, MatTooltipModule],
  exports: [FuseShortcutsComponent],
  // providers: [CookieService],
})
export class FuseShortcutsModule {}
