import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPlant } from '@common/models/common.model';
import { CustomHttpClient, IApiListResponse, IApiResponse } from '@services/http';

@Injectable()
export class PlantService {
  private apiName: string = '/plants';

  constructor(private _http: CustomHttpClient) {}

  public getList(params?: any): Observable<IApiListResponse<IPlant>> {
    return this._http.Get<any>(`${this.apiName}`, {
      params,
    });
  }

  public getFilter(): Observable<IApiListResponse<IPlant>> {
    return this._http.Get(`${this.apiName}/filter`);
  }

  public getDetail(id: number | string): Observable<any> {
    return this._http.Get<any>(`${this.apiName}/${id}`);
  }

  public update(data: any): Observable<IApiResponse> {
    return this._http.Put(`${this.apiName}/${data.id}`, data);
  }
}
