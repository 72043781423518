import dayjs from 'dayjs';
import 'dayjs/plugin/weekOfYear';

export const Gender = {
  FEMALE: 0,
  MALE: 1,
  OTHER: 2,
};

export enum TableType {
  FORM = 0,
  LIST = 1,
}

export const FormType = {
  FORM: { id: TableType.FORM, name: 'Form' },
  LIST: { id: TableType.LIST, name: 'List' },
};

export const EnumFormularForm = {
  SUM: { id: 1, name: 'Sum' },
  COUNT: { id: 2, name: 'Count' },
  AVG: { id: 3, name: 'Avg' },
};

export enum ControlType {
  TEXTBOX = 'TextBox',
  COMBOBOX = 'Combobox',
  LABEL = 'Label',
  DATEPICKER = 'DatePicker',
  DATETIMEPICKER = 'DatetimePicker',
  CHECKBOX = 'Checkbox',
  RADIOBUTTON = 'RadioButton',
  PICKTIMEBUTTON = 'PicktimeButton',
  PICKCURRENTUSER = 'PickCurrentUser',
  AUTOPICKCURRENTUSER = 'AutoPickCurrentUser',
  TEXTAREA = 'Textarea',
  // AUTONUMBER = 'AutoNumber',
  AUTOALPHABET = 'AutoAlphabet',
  NUMBER = 'Number',
  SCAN = 'Scan',
  COLORPICKER = 'ColorPicker',
  TIMEPICKER = 'TimePicker',
  AUTODATETIME = 'AutoDatetime',
  TIMEINPUT = 'TimeInput',
  HTML = 'Html',
  AUTOINDEX = 'AutoIndex',
  IMAGEPICKER = 'ImagePicker',
  MULTISELECT = 'MultiSelect',
  YEARPICKER = 'YearPicker',
}

export enum ControlPropertyEnum {
  DEFAULT = 'Default',
  READONLY = 'Readonly',
  DATASOURCE = 'Datasource',
  MIN = 'Min',
  MAX = 'Max',
  MINCOLOR = 'MinColor',
  MAXCOLOR = 'MaxColor',
  FORMULA = 'Formula',
  // VALIDATION = 'Validation',
  ISTOTAL = 'IsTotal',
  // QUANTITY = 'Quantity',
  MASK = 'Mask',
  REQUIRED = 'Required',
  UNIQUE = 'Unique',
  COPY = 'Copy',
  MINTOTAL = 'MinTotal',
  MAXTOTAL = 'MaxTotal',
  WIDTH = 'Width',
  REFERENCE = 'Reference',
  STARTINDEX = 'StartIndex',
  ISCHECKALL = 'IsCheckAll',
}

export const FormTemplateStatus = {
  WAITINGCONFIRM: { id: 1, code: 'WaitingConfirm', name: 'Waiting Confirm', color: '#ff9800' },
  CONFIRMED: { id: 2, code: 'Confirmed', name: 'Confirmed', color: '#4caf50' },
  REJECTED: { id: 3, code: 'Rejected', name: 'Rejected', color: '#F44336' },
};

export const FormDataStatus = {
  PENDING: { id: 1, code: 'Pending', name: 'STATUS.PENDING', color: '#FCEE4B' },
  INPROGRESS: { id: 2, code: 'InProgress', name: 'STATUS.INPROCESS', color: '#039be5' },
  REPROGRESS: { id: 3, code: 'ReProgress', name: 'STATUS.REPROCESS', color: '#F44336' },
  INREVIEW: { id: 4, code: 'InReview', name: 'STATUS.INREVIEW', color: '#f44336' },
  QCCHECKED: { id: 5, code: 'QCChecked', name: 'STATUS.QCCHECK', color: '#3f51b5' },
  COMPLETED: { id: 6, code: 'Completed', name: 'STATUS.COMPLETED', color: '#8EE269' },
};

export const Operator = [
  { id: 1, name: '=' },
  { id: 2, name: '>' },
  { id: 3, name: '<' },
  { id: 4, name: '>=' },
  { id: 5, name: '<=' },
];

// https://momentjs.com/docs/#/displaying/format/
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
export const MY_OWL_FORMATS = {
  // parseInput: 'l LT',
  // fullPickerInput: 'l LT',
  // datePickerInput: 'l',
  // timePickerInput: 'LT',
  // monthYearLabel: 'MMM YYYY',
  // dateA11yLabel: 'LL',
  // monthYearA11yLabel: 'MMMM YYYY',
  parseInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false },
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric', hour12: false },
  monthYearLabel: { year: 'numeric', month: '2-digit' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

export const EditorOptions = {
  base_url: '/assets/tinymce', // Root for resources
  suffix: '.min', // Suffix to use when loading resources
  height: 100,
  toolbar: 'bold italic underline fontsizes | forecolor backcolor | align | undo redo',
  selector: 'editor#editorId', // change this value according to your HTML
  content_style: 'body { margin: 4px 8px }',
  forced_root_block: 'div',
  branding: false,
  menubar: false,
  statusbar: false,
  // images_upload_url: 'postAcceptor.php',
  file_picker_types: 'image',
  // br_in_pre: false,
  // force_br_newlines : true,
  // force_p_newlines : false,
  // formats: {
  //   // Changes the alignment buttons to add a class to each of the matching selector elements
  //   alignleft: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'left' },
  //   aligncenter: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'center' },
  //   alignright: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'right' },
  //   alignjustify: { selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img', classes: 'full' },
  //   forecolor: { inline: 'span', classes: 'forecolor', styles: { color: '%value' } },
  //   backcolor: { inline: 'span', classes: 'backcolor', styles: { color: '%value' } },
  // }
};

//  moment().isoWeeksInYear() = dayjs.extend(weekOfYear) ---- define: 10/17/2022
//code - new lib version
const weekOfYear = require('dayjs/plugin/weekOfYear');
export const WeekList = Array.from(Array(dayjs.extend(weekOfYear)), (_, i) => i + 1).map((v) => ({ id: v, name: v }));

//code - lib moment version
// export const WeekList = Array.from(Array(moment().isoWeeksInYear()), (_, i) => i + 1).map((v) => ({ id: v, name: v }));

export const MonthList = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 },
  { id: 8, name: 8 },
  { id: 9, name: 9 },
  { id: 10, name: 10 },
  { id: 11, name: 11 },
  { id: 12, name: 12 },
];
export const QuarterList = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
];

export const MatButtonType = {
  basic: 'mat-button',
  raised: 'mat-raised-button',
  flat: 'mat-flat-button',
  stroked: 'mat-stroked-button',
  icon: 'mat-icon-button',
  fab: 'mat-fab',
};

export const FilterKey = {
  genericList: {
    filterData: 'genericList.filterData',
    displayedColumns: 'genericList.displayedColumns',
  },
  form: {
    formDataList: 'formdatalist',
  },
  fci: {
    areaList: 'fci.areaList.params',
    reportPareto: 'fci.reportPareto.params',
    reportAm: 'fci.reportAm.params',
    innovation: {
      filterParams: 'fci.innovation.params',
      displayedColumns: 'fci.innovation.columns',
    },
    cilDataReport: 'fci.cilDataReport.params',
    cilChecklist: 'fci.cilChecklist.params',
  },
  myConfirm: {
    formChecklist: 'confirm.formChecklist.params',
    cilChecklist: 'confirm.cilChecklist.params',
  },
};

export enum SettingKey {
  FormDataColumns = 'FormData.Columns',
  FormDataMyFormColumns = 'FormData.MyForm.Columns',
}

export enum FileType {
  FileLog = 0,
  Image = 1,
  Document = 2,
}

export enum EnumDatasourceType {
  None = 5,
  Product = 10,
  Line_Machine = 15,
  Department = 20,
  User = 25,
}

export enum EnumEntityName {
  Product = 'Product',
  Material = 'Material',
  WorkOrder = 'WorkOrder',
}
// TODO remove keygroup, use enum id
export const EntityNameList = [
  { id: 1, name: EnumEntityName.Product },
  { id: 2, name: EnumEntityName.WorkOrder },
  { id: 3, name: EnumEntityName.Material },
];

export enum ApiFormType {
  NeedToDo = 'NeedToDo',
  MyForm = 'MyForm',
  AllForm = 'AllForm',
}

export enum CustomReportType {
  PowerBI = 0,
  ReportMenu = 1,
}

export const MachineType = [
  { id: 0, name: 'Đầu lọc' },
  { id: 5, name: 'Đục lỗ sáp' },
];

export enum PeriodEnum {
  Daily = 10,
  Monthly = 20,
  Year = 25,
}

export enum ProductTypeEnum {
  CDL = 1,
  SDL = 2,
}

export const ProductTypeReport = [
  { id: ProductTypeEnum.CDL, name: 'Cây đầu lọc' },
  { id: ProductTypeEnum.SDL, name: 'Sáp đục lỗ' },
];

export const ScheduleShiftType = [
  { id: 5, name: 'A' },
  { id: 10, name: 'B' },
  { id: 15, name: 'C' },
];

export const ScheduleShiftTimeType = [
  { id: 5, name: 'Sáng' },
  { id: 10, name: 'Đêm' },
];

export const DateAndMonth = [
  { id: '3', name: 'Ngày' },
  { id: '2', name: 'Tháng' },
];

export const ConvertedProductType = [
  { id: 5, name: 'Thành Phẩm' },
  { id: 10, name: 'Thành Phẩm Qui Đổi' },
];

export const ConvertedProductTypeProductProportion = [
  { id: 5, name: 'Thành Phẩm' },
  { id: 10, name: 'Thành Phẩm Qui Đổi' },
  { id: 15, name: 'Số lượng (Kg)' },
];

export const ConvertedTowType = [
  { id: 5, name: 'Tow NL' },
  { id: 10, name: 'Size' },
  { id: 15, name: 'Chiều dài' },
  { id: 20, name: 'Loại đầu lọc' },
];

// export const ConvertedTowSizeType = [
//   { id: 5, name: 'Tow NL' },
//   { id: 10, name: 'Size' },
// ];

export const UnitList = [
  { id: 'thùng', name: 'thùng' },
  { id: 'hộp', name: 'hộp' },
  { id: 'cuộn', name: 'cuộn' },
];

export const CauseStop = [
  { id: 5, name: 'Thời gian' },
  { id: 10, name: 'Tần suất' },
];

export enum EnumReportTabType {
  ByDate = 5,
  ByShift = 10,
  Summary = 15,
  PrimaryWO = 20,
  SubWO = 25,
}

export const QuickReportAndFinishedGoodsList = [
  { id: EnumReportTabType.ByDate, name: 'Theo ngày' },
  { id: EnumReportTabType.ByShift, name: 'Theo ca' },
  { id: EnumReportTabType.Summary, name: 'Tổng hợp' },
];

export const MonthlyConsumptionList = [
  { id: EnumReportTabType.PrimaryWO, name: 'Lệnh Sản Xuất Chính' },
  { id: EnumReportTabType.SubWO, name: 'Lệnh Sản Xuất Phụ' },
];

export const PeriodType = [
  { id: PeriodEnum.Daily, name: 'Ngày' },
  { id: PeriodEnum.Monthly, name: 'Tháng' },
  { id: PeriodEnum.Year, name: 'Năm' },
];

export enum EnumProductLengthType {
  None = 0,
  BAT = 5,
  XK = 10,
}

export const ProductLengthType = [
  { id: EnumProductLengthType.None, name: 'None', productCategoryId: 1 },
  { id: EnumProductLengthType.BAT, name: 'BAT', productCategoryId: 1 },
  { id: EnumProductLengthType.XK, name: 'XK', productCategoryId: 1 },
];

export enum SizeEnum {
  CK = 5,
  DS = 10,
  SL = 15,
  SS = 20,
}

export const SizeType = [
  { id: SizeEnum.CK, name: 'CK' },
  { id: SizeEnum.DS, name: 'DS' },
  { id: SizeEnum.SL, name: 'SL' },
  { id: SizeEnum.SS, name: 'SS' },
];

export enum EnumConversionFactorType {
  None = 1,
  ShowLayerNumber = 2,
  ShowPiecesNumber = 3,
}

export const ConversionFactorTypes = [
  { id: EnumConversionFactorType.None, name: 'Không hiển thị' },
  { id: EnumConversionFactorType.ShowLayerNumber, name: 'Hiển thị số lớp sản xuất' },
  { id: EnumConversionFactorType.ShowPiecesNumber, name: 'Hiển thị số tờ rời' },
];

export enum EnumUnitType {
  Carton = 1,
  Box = 2,
  Roll = 3,
  Sheet = 4,
  Kg = 5,
}

export const UnitType = [
  { id: EnumUnitType.Carton, name: 'Thùng' },
  { id: EnumUnitType.Box, name: 'Hộp' },
  { id: EnumUnitType.Roll, name: 'Cuộn' },
  { id: EnumUnitType.Sheet, name: '1000 tờ' },
  { id: EnumUnitType.Kg, name: 'Kg' },
];

export enum EnumPlant {
  CDL = 1,
  IN = 2,
}

export enum EnumWorkOrderType {
  Primary = 1,
  Test = 2,
  Blank = 3,
  Offset = 4,
}

export const WorkOrderTypes = [
  { id: EnumWorkOrderType.Primary, name: 'Lệnh chính', color: '#4CAF50' },
  { id: EnumWorkOrderType.Test, name: 'Lệnh thử', color: '#49ceeb' },
  { id: EnumWorkOrderType.Blank, name: 'Lệnh trắng', color: '#1e11d6' },
  { id: EnumWorkOrderType.Offset, name: 'Lệnh bù', color: '#d50873' },
];

export enum EnumPlanStatus {
  NotStart = 1,
  InProgress = 2,
  Completed = 3,
  Unfinished = 4,
  Pending = 5,
}

export const PlanStatuses = [
  { id: EnumPlanStatus.NotStart, name: 'Chưa sản xuất', color: '#1e11d6' },
  { id: EnumPlanStatus.InProgress, name: 'Đang sản xuất', color: '#49ceeb' },
  { id: EnumPlanStatus.Completed, name: 'Đã hoàn thành', color: '#4CAF50' },
  { id: EnumPlanStatus.Unfinished, name: 'Sản xuất dở dang', color: '#d50873' },
  { id: EnumPlanStatus.Pending, name: 'Chờ sản xuất', color: '#ffa500' },
];

// Hard Code
export const FormNameColors = [
  { name: 'Thay Đổi', color: '#E64A19' },
  { name: 'Kcs', color: '#0000ff' },
  { name: 'Công Việc', color: '#008000' },
];

export enum EnumMachineStatus {
  PendingMaintenance = 0, // chưa bảo trì
  Maintained = 1, // đang bảo trì
  MaintenanceOnTime = 2, // bảo trì đúng thời hạn
}

export const MachineStatuses = [
  { id: EnumMachineStatus.PendingMaintenance, name: 'Chưa bảo trì', color: '#ef4444' },
  { id: EnumMachineStatus.Maintained, name: 'Đang bảo trì', color: '#039be5' },
  { id: EnumMachineStatus.MaintenanceOnTime, name: 'Bảo trì đúng thời hạn', color: '#4CAF50' },
];
