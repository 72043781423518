import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseNavbarComponent } from 'app/main/navbar/navbar.component';

@NgModule({
  declarations: [FuseNavbarComponent],
  imports: [RouterModule, MatButtonModule, MatIconModule, FuseSharedModule, FuseNavigationModule],
  exports: [FuseNavbarComponent],
})
export class FuseNavbarModule {}
