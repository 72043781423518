import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '@services/auth';
import { tap } from 'rxjs/operators';
import { Broadcaster, BroadcastKey } from '@common/modules/share/broadcaster.service';
import { Util } from '../util';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private anonymousUrl: string[] = [
    'token',
    'register',
    'assets/i18n',
    'https://onesignal.com/api/v1/notifications',
    '/auth/login-redirect',
    '/version.json',
    '/languages',
    '/public',
  ];

  constructor(private inj: Injector, private broadcaster: Broadcaster, private _util: Util, private tokenExtractor: HttpXsrfTokenExtractor) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // TODO: check url of activatedroute when need
    const auth = this.inj.get(AuthService);
    const token = auth.getToken();
    const cookieheaderName = 'X-XSRF-TOKEN';
    // let csrfToken = this._util.getCookie('xsrf-token');
    let csrfToken = this.tokenExtractor.getToken() as string;
    if (csrfToken && !req.headers.has(cookieheaderName)) {
      req = req.clone({ headers: req.headers.set(cookieheaderName, csrfToken) });
      console.log('csrfToken', csrfToken, req);
    }
    if (this.anonymousUrl.findIndex((i) => req.url.toLowerCase().indexOf(i.toLowerCase()) >= 0) < 0) {
      if (!auth.isAuthenticated) {
        // Error
        auth.logout();
        return;
      } else {
        const authReq = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${token.accessToken}`,
            'X-Timezone-Offset': '' + new Date().getTimezoneOffset(),
          },
        });
        const startingTime = Date.now();
        return next.handle(authReq).pipe(
          tap((evt) => {
            if (evt instanceof HttpResponse) {
              const timePassed = (Date.now() - startingTime) / 1000; // seconds
              const size = (evt.headers ? +evt.headers.get('Content-Length') || 0 : 0) / 1000; // KB
              this.broadcaster.fire(BroadcastKey.NETWORK_SPEED, (size / timePassed).toFixed(2));
            }
          })
        );
      }
    }
    return next.handle(req);
  }
}
