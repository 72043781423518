<div class="w-100-p overflow-auto">
  <table mat-table #table [dataSource]="dataSource" matSort [class.table-hover]="options.rowClickable" [ngClass]="tableClass">
    <ng-container [matColumnDef]="c.name" *ngFor="let c of options.columns">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!c.ordering" [fxHide]="c.hide" [style.width]="c.width || 'auto'">
        <mat-checkbox
          *ngIf="c.type === 'select'"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
        <div fxLayout="row" fxLayoutAlign="start center" *ngIf="c.title">
          <span>{{ c.title | translate }}</span>
          <div *ngIf="c.filter">
            <mat-icon
              class="ml-4 secondary-text"
              [fontSet]="c['filterValue'] ? 'material-icons' : 'material-icons-outlined'"
              (click)="$event.stopPropagation()"
              [matMenuTriggerFor]="filterMenu"
              >filter_alt</mat-icon
            >
            <mat-menu #filterMenu="matMenu" [overlapTrigger]="false" [hasBackdrop]="true" (closed)="filterClosed(c)">
              <mat-form-field class="pl-8 pr-8 w-100-p" floatLabel="never">
                <mat-select [(ngModel)]="c['filterCondition']" (click)="$event.stopPropagation()">
                  <mat-option (click)="$event.stopPropagation()" *ngFor="let item of conditionList | filter : c.type || 'text'" [value]="item.id">{{
                    item.name
                  }}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="pl-8 pr-8 w-100-p" floatLabel="never">
                <input matInput [(ngModel)]="c['filterValue']" placeholder="{{ c.title | translate }}" (click)="$event.stopPropagation()" />
                <span matSuffix class="font-size-24">
                  <button mat-icon-button (click)="filter(c)"><mat-icon class="secondary-text">check_circle</mat-icon></button>
                </span>
              </mat-form-field>
            </mat-menu>
          </div>
        </div>
      </th>
      <td mat-cell *matCellDef="let row" [fxHide]="c.hide" [style.width]="c.width || 'auto'" [ngClass]="c.className">
        <mat-checkbox
          *ngIf="c.type === 'select'"
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleRow(row) : null"
          [checked]="selection.isSelected(row)"
          [disabled]="options.checkActiveRow && row[options.checkActiveRow] === false"
        >
        </mat-checkbox>
        <span class="word-break" *ngIf="!c.type || c.type === 'text' || c.type === 'number'">{{
          c.childName && row[c.name] ? row[c.name][c.childName] : row[c.name]
        }}</span>
        <span class="word-break" *ngIf="c.type === 'translate'">{{ row[c.name] | translate }}</span>
        <span class="text-truncate" *ngIf="c.type === 'link'">
          <a class="cursor-pointer" (click)="$event.stopPropagation(); clickRow(row)">{{ row[c.name] }}</a>
        </span>
        <span class="text-truncate" *ngIf="c.type === 'date'">{{ row[c.name] | date : c.pipeName || pipe.fullDate }}</span>
        <div *ngIf="c.type === 'active'" fxLayoutAlign="start center">
          <mat-icon *ngIf="row[c.name]" class="active-icon mat-green-600-bg s-18">check</mat-icon>
          <mat-icon *ngIf="!row[c.name]" class="active-icon mat-red-500-bg s-18">close</mat-icon>
        </div>
        <div *ngIf="c.type === 'image_sm'">
          <img class="image_sm" [src]="domain + (row[c.name] || 'assets/images/No-image-found.jpg')" />
        </div>
        <div *ngIf="c.type === 'template'">
          <ng-container *ngTemplateOutlet="c.template; context: { data: row }"> </ng-container>
        </div>
      </td>
      <td mat-footer-cell *matFooterCellDef [attr.colspan]="c.footerColspan" [style.display]="c.footerColspan === 0 ? 'none' : ''">
        <ng-container *ngTemplateOutlet="c.footerTemplate"> </ng-container>
      </td>
    </ng-container>

    <!-- Buttons Column -->
    <ng-container [matColumnDef]="options.actionButtons" *ngIf="options.actionButtons">
      <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.ACTION' | translate }}</th>
      <td mat-cell *matCellDef="let row" class="d-table-cell">
        <div fxFlex="row" fxLayoutAlign="center center">
          <ng-template #actionButtons>
            <button mat-icon-button (click)="$event.stopPropagation(); clickExtraButton(row)" aria-label="Extra link" *ngIf="options.extraButton">
              <mat-icon>{{ options.extraButton?.icon || 'link' }}</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); clickEdit(row)" aria-label="Edit" *ngIf="options.showEdit">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="$event.stopPropagation(); clickDelete(row.id)" aria-label="Delete" *ngIf="options.showDelete">
              <mat-icon>delete</mat-icon>
            </button>
          </ng-template>
          <div fxLayoutAlign="start start" fxShow>
            <!-- fxHide.lt-md -->
            <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
          </div>
          <!-- <button mat-icon-button fxHide.gt-sm [matMenuTriggerFor]="moreMenu" (click)="$event.stopPropagation()" aria-label="More">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #moreMenu="matMenu" fxHide.gt-sm>
            <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
          </mat-menu> -->
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="$event.stopPropagation(); clickRow(row)"
      (dblclick)="$event.stopPropagation(); dbClickRow(row)"
      [style.height]="options.rowHeight"
      [ngClass]="options.rowClass ? options.rowClass(row) : ''"
    ></tr>
    <ng-container *ngIf="options.showFooter">
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </ng-container>
  </table>
  <div class="text-center secondary-text py-8" *ngIf="!isLoadingResults && (!dataSource || !dataSource.data || !dataSource.data.length)">
    {{ 'COMMON.NODATA' | translate }}
  </div>
</div>
<mat-progress-bar mode="indeterminate" *ngIf="isLoadingResults"></mat-progress-bar>
<div class="table-footer h-56" fxLayoutAlign="end center" [fxShow]="options.pagination || options.showDisplayColumn">
  <!-- COLUMNS -->
  <div fxLayoutAlign="start center" *ngIf="options.showDisplayColumn">
    <label for="columns" class="mr-8">
      <mat-icon class="secondary-text">view_column</mat-icon>
    </label>
    <mat-form-field floatLabel="never">
      <mat-select id="columns" placeholder="Please select columns" [(value)]="displayedColumns" multiple (selectionChange)="columnChange()">
        <mat-select-trigger>
          <span *ngIf="displayedColumns?.length">{{ displayedColumns.length }} {{ 'selected column(s)' | translate }}</span>
        </mat-select-trigger>
        <mat-option *ngFor="let c of allColumns" [value]="c.name">{{ c.title | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <!-- / COLUMNS -->
  <mat-paginator
    #paginator
    [class.hidden]="!options.pagination"
    [length]="resultsLength || 0"
    [pageIndex]="pageIndex || 0"
    [pageSize]="pageSize || 20"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
  >
  </mat-paginator>
</div>
