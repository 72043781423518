import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppConstant, AuthService, CustomHttpClient } from '@common/services';
import { TranslateService } from '@ngx-translate/core';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-webstorage';
import { firstValueFrom, fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Broadcaster, BroadcastKey } from './broadcaster.service';
import { createTransaction, openDatabase, optionsGenerator, validateBeforeTransaction } from './indexdbUtil';

export interface IOfflineData {
  group: string;
  tableId: number;
  userId: string;
  values: { value: any; formDataId: number; formControlId: number; groupData: string; formControlValueId?: number }[];
  errorMessage?: string;
  formDataId?: number;
}

@Injectable()
export class NetworkService implements OnDestroy {
  public networkStatus: boolean = false;
  public networkStatus$: Subscription = Subscription.EMPTY;
  private _isConnected: boolean = navigator ? navigator.onLine : true;
  private _isSync: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private _http: CustomHttpClient,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private broadcaster: Broadcaster,
    private dbService: NgxIndexedDBService
  ) {
    const timeSync = this.localStorageService.retrieve('offline.timeSync') || AppConstant.offlineConfig.timeSync;
    setInterval(() => {
      if (this._isConnected && !this._isSync) {
        this.sendData().then();
      }
    }, timeSync);
    this.checkNetworkStatus();
  }

  public get isConnected() {
    return this._isConnected;
  }
  public set isConnected(c) {
    this._isConnected = c;
  }

  ngOnDestroy(): void {
    this.networkStatus$.unsubscribe();
  }
  public checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(of(null), fromEvent(window, 'online'), fromEvent(window, 'offline'))
      .pipe(map(() => navigator.onLine))
      .subscribe((isConnected) => {
        console.log('networkStatus', isConnected);
        this.networkStatus = isConnected;
        this.broadcaster.fire(BroadcastKey.NETWORK_CONNECTION, isConnected);
        this._isConnected = isConnected;
        if (this._isConnected) {
          this.sendData().then();
        }
      });
  }

  // DATA OF formControlValue
  public getAllData(): Observable<IOfflineData[]> {
    try {
      return this.dbService.getAll('formControlValue');
    } catch (error) {
      this.handleError(error, 'getAllData');
      return of([]);
    }
  }
  public getDataByTableId(tableId): Observable<IOfflineData[]> {
    return this.dbService.getAllByIndex('formControlValue', 'tableId', IDBKeyRange.only(tableId));
  }

  public countData(): Observable<number> {
    let total = 0;
    try {
      return this.dbService.count('formControlValue');
    } catch (error) {
      this.handleError(error, 'countData');
    }
    this.broadcaster.fire(BroadcastKey.OFFLINE_DATA_ROWS, total);
    return of(total);
  }

  public async pushData(d: IOfflineData) {
    const currentDataTable = await firstValueFrom(this.getDataByTableId(d.tableId));
    if (currentDataTable && currentDataTable.some((x: IOfflineData) => x.group === d.group)) {
      return;
    }
    const values = [...new Map(d.values?.map((item) => [item['formControlId'], item])).values()]; // distinct duplicate formControlId
    this.dbService.add('formControlValue', { group: d.group, values: values, userId: d.userId, tableId: d.tableId }).subscribe({
      next: (resp) => {
        this.sendData().then();
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public clearData(data) {
    data.forEach((d) => {
      this.dbService.delete('formControlValue', d.group + '').subscribe((results) => {
        // console.log(results);
      });
    });
    this.countData();
  }

  public clearAll() {
    this.dbService.clear('formControlValue').subscribe(() => {
      this.countData();
    });
    this.dbService.clear('formControlValueSent').subscribe(() => {});
  }

  public clearError(data: IOfflineData) {
    this.dbService.getByKey('formControlValue', data.group).subscribe({
      next: (d: IOfflineData) => {
        if (d) {
          d.errorMessage = '';
          this.dbService.update('formControlValue', d).subscribe(() => {
            this.sendData().then();
          });
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  public async clearErrors() {
    const data = [];
    return new Promise<any>((resolve, reject) => {
      openDatabase(AppConstant.dbConfig.name, AppConstant.dbConfig.version).then((db: IDBDatabase) => {
        const isValid = validateBeforeTransaction(db, 'formControlValue', reject);
        if (!isValid) {
          db.close();
          resolve([]);
          return;
        }
        const transaction = createTransaction(db, optionsGenerator('readwrite', 'formControlValue', reject, resolve));
        const objectStore = transaction.objectStore('formControlValue');
        const request = objectStore.openCursor();
        request.onsuccess = (e: any) => {
          const cursor = e.target.result;
          if (cursor) {
            if (cursor.value.errorMessage) {
              cursor.value.errorMessage = '';
              cursor.update(cursor.value);
              data.push(cursor.value);
            }
            cursor.continue();
          } else {
            resolve(data);
          }
        };
      });
    });
  }

  public async getDataByRows() {
    const postRows = this.localStorageService.retrieve('offline.postRows') || AppConstant.offlineConfig.postRows;
    const postData = [];
    let i = 0;
    return new Promise<any>((resolve, reject) => {
      openDatabase(AppConstant.dbConfig.name, AppConstant.dbConfig.version).then((db: IDBDatabase) => {
        const isValid = validateBeforeTransaction(db, 'formControlValue', reject);
        if (!isValid) {
          db.close();
          resolve([]);
          return;
        }
        const transaction = createTransaction(db, optionsGenerator('readonly', 'formControlValue', reject, resolve));
        const objectStore = transaction.objectStore('formControlValue');
        const request = objectStore.openCursor();
        request.onsuccess = (e: any) => {
          const cursor = e.target.result;
          if (cursor && i < postRows) {
            if (!cursor.value.errorMessage) {
              postData.push(cursor.value);
              i += 1;
            }
            cursor.continue();
          } else {
            resolve(postData);
          }
        };
      });
    });
  }

  // DATA OF formControlValueSent
  public async getSentDataByFormId(formDataId) {
    // return await this.dbService.getByIndex('formControlValueSent', 'formDataId', IDBKeyRange.only(formDataId));
    const expiryDataSent = Date.now() - 2 * 24 * 60 * 60 * 1000; // 2 days
    const postData = [];
    return new Promise<any>((resolve, reject) => {
      openDatabase(AppConstant.dbConfig.name, AppConstant.dbConfig.version).then((db: IDBDatabase) => {
        const isValid = validateBeforeTransaction(db, 'formControlValueSent', reject);
        if (!isValid) {
          db.close();
          resolve([]);
          return;
        }
        const transaction = createTransaction(db, optionsGenerator('readwrite', 'formControlValueSent', reject, resolve));
        const objectStore = transaction.objectStore('formControlValueSent');
        const request = objectStore.openCursor();
        request.onsuccess = (e: any) => {
          const cursor = e.target.result;
          if (cursor) {
            if (+cursor.value.group < expiryDataSent) {
              cursor.delete();
            } else if (cursor.value.formDataId === formDataId) {
              postData.push(cursor.value);
            }
            cursor.continue();
          } else {
            resolve(postData);
          }
        };
      });
    });
  }
  // public getSentDataByTableId(tableId): Observable<IOfflineData[]> {
  //   return this.dbService.getAllByIndex('formControlValueSent', 'tableId', IDBKeyRange.only(tableId));
  // }
  public clearSentData(data) {
    data.forEach((d) => {
      this.dbService.delete('formControlValueSent', d.group + '').subscribe((results) => {});
    });
  }

  public async sendData() {
    const reject = new Promise<any>((resolve, reject) => {
      resolve({ message: 'Reject' });
    });
    if (this._isSync) {
      return reject;
    }
    const dataLength = await firstValueFrom(this.countData());
    if (!dataLength) {
      return reject;
    }
    if (this._isConnected && !this._isSync) {
      let postData: IOfflineData[] = [];
      try {
        postData = await this.getDataByRows();
      } catch (error) {
        this.handleError(error, 'sendData');
        return reject;
      }
      if (!postData[0]) {
        return reject;
      }
      let accessToken = '';
      if (postData[0].userId + '' === this._authService.currentUser.id + '') {
        accessToken = this._authService.getToken().accessToken;
      } else {
        let user = null;
        try {
          user = await firstValueFrom(this.dbService.getByKey('user', '' + postData[0].userId));
        } catch (error) {
          this.handleError(error, 'sendData');
          return reject;
        }
        if (user) {
          accessToken = user.accessToken;
        } else {
          postData[0].errorMessage =
            this._translateService.instant('OFFLINE_USER_NOTFOUND') +
            ' (ID: ' +
            postData[0].userId +
            '). ' +
            this._translateService.instant('PLEASE_LOGIN_LOGOUT');
          this.dbService.update('formControlValue', postData[0]).subscribe();
          return reject;
        }
      }

      postData = postData.filter((pd) => pd.userId === postData[0].userId);
      if (!postData.length || !accessToken) {
        return reject;
      }
      const httpHeaders = new HttpHeaders({
        // eslint-disable-next-line quote-props
        Authorization: 'Bearer ' + accessToken,
        'X-Timezone-Offset': '' + new Date().getTimezoneOffset(),
      });
      let postDataValues = [];
      postData.forEach((d: IOfflineData) => {
        postDataValues = postDataValues.concat(d.values);
      });
      // console.log('postData: ', postData);
      this._isSync = true;
      this.broadcaster.fire(BroadcastKey.SYNC_DATA, { isSync: true, data: [] });
      return new Promise<any>((resolve, rj) => {
        this._http
          .Post<any>(AppConstant.domain + '/api/formcontrolvalues', { formControlValueAddModel: postDataValues }, { headers: httpHeaders, noIntercept: true })
          .subscribe({
            next: (resp) => {
              this.clearData(postData);
              if (resp && resp.formControlValueResponseModels) {
                postData.forEach((d) => {
                  d.values.forEach((v) => {
                    const formControlId = resp.formControlValueResponseModels.find((fcv) => fcv.groupData === d.group && fcv.formControlId === v.formControlId);
                    v.formControlValueId = formControlId ? formControlId.formControlValueId : '';
                  });
                  d.formDataId = d.values && d.values.length && d.values[0].formDataId;
                  this.dbService.add('formControlValueSent', d).subscribe(() => {});
                });
              }
              this._isSync = false;
              this.broadcaster.fire(BroadcastKey.SYNC_DATA, { isSync: false, data: postData });
              resolve({ message: 'Success' });
            },
            error: (err) => {
              let errorMessage = 'Status: ' + err.status;
              // add error for offline data
              // try {
              //   errorMessage += ', ' + JSON.stringify(err.error || err);
              // } catch (error) {
              //   console.log(error);
              // }
              // postData.forEach((d) => {
              //   d.errorMessage = errorMessage;
              //   this.dbService.update('formControlValue', d).subscribe();
              // });
              // clear data if server error
              this.clearData(postData);
              this._isSync = false;
              this.broadcaster.fire(BroadcastKey.SYNC_DATA, { isSync: false, data: [] });
              resolve({ message: errorMessage });
            },
            complete: () => {
              this._isSync = false;
            },
          });
      });
    } else {
      return reject;
    }
  }

  public handleError(e: string, method) {
    e = e + '';
    console.log('HANDLE ERROR: ', method, e);
    if (e.includes('objectStore does not exists')) {
      this.deleteDatabase();
    }
  }

  public deleteDatabase() {
    try {
      const indexedDB: IDBFactory = window.indexedDB; // || /** @type {?} */ window.mozIndexedDB || /** @type {?} */ window.webkitIndexedDB || /** @type {?} */ window.msIndexedDB;
      indexedDB.deleteDatabase(AppConstant.dbConfig.name);
      window.location.reload();
    } catch (error) {
      console.log(error);
      // window.location.reload(true);
    }
  }
}
