<div class="demo-sidenav">
    <mat-list>
        <h3 matSubheader>Sidenav Demo</h3>

        <mat-list-item>
            <span>Sidenav Item 1</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 2</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 3</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 4</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 5</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 6</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 7</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 8</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 9</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 10</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 11</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 12</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 13</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 14</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 15</span>
        </mat-list-item>

        <mat-divider></mat-divider>

        <mat-list-item>
            <span>Sidenav Item 16</span>
        </mat-list-item>

    </mat-list>
</div>
