import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fuseConfig } from 'app/fuse-config';
import { AppConfigService } from '@common/modules/share/appConfig.service';

// Define the default config
const DEFAULT_CONFIG = fuseConfig;
// Define the default config for mobile
const DEFAULT_CONFIG_MOBILE = {
  layout: {
    navigation: 'none', // 'right', 'left', 'top', 'none'
    navigationFolded: true, // true, false
    toolbar: 'none', // 'above', 'below', 'none'
    footer: 'none', // 'above', 'below', 'none'
    mode: 'fullwidth', // 'boxed', 'fullwidth'
  },
  colorClasses: {
    toolbar: 'mat-white-500-bg',
    navbar: 'mat-fuse-dark-700-bg',
    footer: 'mat-fuse-dark-900-bg',
  },
  customScrollbars: false,
  routerAnimation: 'none', // fadeIn, slideUp, slideDown, slideRight, slideLeft, none
};

// Create the injection token for the custom config
export const FUSE_CONFIG = new InjectionToken('fuseCustomConfig');

@Injectable()
export class FuseConfigService {
  public config: any;
  public defaultConfig: any;

  public onConfigChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param router
   * @param platform
   * @param config
   */
  constructor(
    private router: Router,
    public platform: Platform,
    private deviceService: DeviceDetectorService,
    @Inject(FUSE_CONFIG) @Optional() config,
    private _appConfigService: AppConfigService
  ) {
    const isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();

    if (isMobile) {
      this.defaultConfig = DEFAULT_CONFIG_MOBILE;
    } else {
      // Set the default settings from the constant
      this.defaultConfig = DEFAULT_CONFIG;

      // If custom config provided with forRoot,
      // use them as default config...
      if (config) {
        this.defaultConfig = config;
      }
    }

    /**
     * Disable Custom Scrollbars if Browser is Mobile
     */
    if (this.platform.ANDROID || this.platform.IOS) {
      this.defaultConfig.customScrollbars = false;
    }

    // Set the config from the default config
    this.config = { ...this.defaultConfig };

    // Reload the default settings for the
    // layout on every navigation start
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const ignoreUrl: string[] = ['/mobile', '/pages'];
        if (ignoreUrl.findIndex((i) => event.url.indexOf(i) >= 0) < 0) {
          // console.log('route change config');
          this.setConfig({
            layout: this.defaultConfig.layout,
          });
        }
      }
    });

    // Create the behavior subject
    this.onConfigChanged = new BehaviorSubject(this.config);
  }

  /**
   * Set the new config from given object
   *
   * @param config
   */
  public setConfig(config): void {
    // Set the config from the given object
    // Ugly, but works for now...
    this.config = {
      ...this.config,
      ...config,
      layout: {
        ...this.config.layout,
        ...config.layout,
      },
      colorClasses: {
        ...this.config.colorClasses,
        ...config.colorClasses,
      },
    };

    // Trigger the event
    this.onConfigChanged.next(this.config);
  }

  public resetDefaultLayoutConfig() {
    // console.log('reset default config');
    this.setConfig({
      layout: this._appConfigService.appConfig.layout,
    });
  }
  public setLayoutNone() {
    this.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none',
      },
    });
  }
  public resetToAppConfig() {
    this.setConfig({
      layout: this._appConfigService.appConfig.layout,
      colorClasses: this._appConfigService.appConfig.colorClasses,
      customScrollbars: this.platform.ANDROID || this.platform.IOS ? false : this._appConfigService.appConfig.customScrollbars,
      routerAnimation: this.deviceService.isMobile() || this.deviceService.isTablet() ? 'none' : this._appConfigService.appConfig.routerAnimation,
    });
  }
}
