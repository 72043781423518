import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [FuseConfirmDialogComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule, FlexLayoutModule, TranslateModule],
  entryComponents: [FuseConfirmDialogComponent],
})
export class FuseConfirmDialogModule {}
