import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollToTopComponent } from './scrollToTop.component';
// import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [ScrollToTopComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatTooltipModule, FlexLayoutModule],
  exports: [ScrollToTopComponent],
  // providers: [CookieService],
})
export class ScrollToTopModule {}
