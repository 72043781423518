import {
  Pipe,
  PipeTransform
} from '@angular/core';

@Pipe({name: 'spaceToDash'})
export class SpaceToDashPipe implements PipeTransform {
  static t(value) {
    return value ? String(value).replace(/ /g, '-') : '';
  }

  transform(value: string, args: any[] = []) {
    return SpaceToDashPipe.t(value);
  }
}
