import { Routes } from '@angular/router';
import { Permission } from '@common/models/permission.model';
import { AuthGuard, PermissionGuard } from '@common/services';
import { PagesComponent } from '@pages/pages.component';
import { HomePageComponent } from './home.component';

export const pageRoutes: Routes = [
  {
    path: 'pages',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'factory',
        loadChildren: () => import('./factory/factory.module').then((m) => m.FactoryModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.FACTORY] },
      },
      // {
      //   path: 'line',
      //   loadChildren: () => import('./line/line.module').then((m) => m.LineModule),
      //   canActivateChild: [PermissionGuard],
      //   data: { permission: [Permission.MASTERDATA.LINE] },
      // },
      {
        path: 'machine',
        loadChildren: () => import('./machine/manchine.module').then((m) => m.MachineModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.MACHINE] },
      },
      {
        path: 'product',
        loadChildren: () => import('./product/product.module').then((m) => m.ProductModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.PRODUCT.VIEW] },
      },
      {
        path: 'material-type',
        loadChildren: () => import('./material-type/material-type.module').then((m) => m.MaterialTypeModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.MATERIALTYPE.CREATE_EDIT_DELETE] },
      },
      {
        path: 'material-management',
        loadChildren: () => import('./material-management/material.module').then((m) => m.MaterialModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.MATERIALMANAGEMENT.CREATE_EDIT_DELETE] },
      },
      {
        path: 'stage',
        loadChildren: () => import('./stage/stage.module').then((m) => m.StageModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.STAGE.CREATE_EDIT_DELETE] },
      },
      {
        path: 'finished-product-type',
        loadChildren: () => import('./finished-product-type/finished-product-type.module').then((m) => m.FinishedProductTypeModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.FINISHED_PRODUCT_TYPE.CREATE_EDIT_DELETE] },
      },
      {
        path: 'machine-product-mapping',
        loadChildren: () => import('./machine-product-mapping/machine-product-mapping.module').then((m) => m.MachineProductMappingModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.MACHINEPRODUCT] },
      },
      {
        path: 'department',
        loadChildren: () => import('./department/department.module').then((m) => m.DepartmentModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.DEPARTMENT] },
      },
      {
        path: 'datasource',
        loadChildren: () => import('./datasource/datasource.module').then((m) => m.DataSourceModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.DATASOURCE] },
      },
      {
        path: 'masterdata-attribute',
        data: { permission: [Permission.MASTERDATA.GENERICATTRIBUTE] },
        canActivateChild: [PermissionGuard],
        loadChildren: () => import('./generic-attribute/generic-attribute.module').then((m) => m.GenericAttributeModule),
      },
      {
        path: 'schedule-group',
        loadChildren: () => import('./schedule/schedule.module').then((m) => m.ScheduleModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.SCHEDULE] },
      },
      {
        path: 'norms-group',
        loadChildren: () => import('./master-data-norms/master-data-norms.module').then((m) => m.MasterDataNormModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.NORMGROUP] },
      },
      {
        path: 'inventory-product',
        loadChildren: () => import('./inventory-product-management/inventory-product.module').then((m) => m.InventoryProductModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.INVENTORYPRODUCT] },
      },
      {
        path: 'inventory-tracking',
        loadChildren: () => import('./inventory-tracking/inventory-tracking.module').then((m) => m.InventoryTrackingModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.MASTERDATA.PRODUCTRESIDUE] },
      },
      {
        path: 'formformat-confirm',
        loadChildren: () => import('./formformat-confirm/formformat-confirm.module').then((m) => m.FormformatConfirmModule),
        canActivateChild: [PermissionGuard],
        data: { permission: Permission.FORM.APPROVEFORMTEMPLATE },
      },
      {
        path: 'formgroup',
        loadChildren: () => import('./formgroup/formgroup.module').then((m) => m.FormGroupModule),
        canActivateChild: [PermissionGuard],
        data: { permission: Permission.FORM.FORMGROUPMANAGEMENT },
      },
      {
        path: 'formformat',
        loadChildren: () => import('./formformat/formformat.module').then((m) => m.FormFormatModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.FORM.FORMTEMPLATEMANAGEMENT] },
      },
      {
        path: 'work-order',
        loadChildren: () => import('./work-order/work-order.module').then((m) => m.WorkOrderModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.OPERATION.WORKORDER.VIEW] },
      },
      {
        path: 'user-management/users',
        data: { permission: [Permission.USER.MANAGEMENT] },
        canActivateChild: [PermissionGuard],
        loadChildren: () => import('./users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'user-management/roles',
        data: { permission: [Permission.USER.ROLE] },
        canActivateChild: [PermissionGuard],
        loadChildren: () => import('./roles/roles.module').then((m) => m.RolesModule),
      },
      {
        path: 'user-management/usergroups',
        data: { permission: [Permission.USER.GROUP] },
        canActivateChild: [PermissionGuard],
        loadChildren: () => import('./usergroups/usergroups.module').then((m) => m.UserGroupsModule),
      },
      {
        path: 'configs',
        data: { permission: [Permission.CONFIGURATION.LANGUAGE, Permission.CONFIGURATION.SETTING, Permission.CONFIGURATION.CONTROLMAPPING] },
        children: [
          { path: '', redirectTo: 'language', pathMatch: 'full' },
          {
            path: 'setting',
            data: { permission: Permission.CONFIGURATION.SETTING },
            canActivateChild: [PermissionGuard],
            loadChildren: () => import('./setting/setting.module').then((m) => m.SettingModule),
          },
          {
            path: 'language',
            data: { permission: Permission.CONFIGURATION.LANGUAGE },
            canActivateChild: [PermissionGuard],
            loadChildren: () => import('./language/language.module').then((m) => m.LanguageModule),
          },
          {
            path: 'notification',
            data: { permission: [Permission.CONFIGURATION.NOTIFICATION.RECEIVEWORKORDER, Permission.CONFIGURATION.NOTIFICATION.OPERATIONWORKORDER] },
            canActivateChild: [PermissionGuard],
            loadChildren: () => import('./notification-setting/notification-setting.module').then((m) => m.NotificationSettingModule),
          },
          {
            path: 'control-mapping',
            data: { permission: Permission.CONFIGURATION.CONTROLMAPPING },
            canActivateChild: [PermissionGuard],
            loadChildren: () => import('./control-mapping/control-mapping.module').then((m) => m.ControlMappingModule),
          },
        ],
      },
      {
        path: 'help',
        loadChildren: () => import('./help/help.module').then((m) => m.HelpModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
      },
      {
        path: 'operation',
        loadChildren: () => import('./operation/operation.module').then((m) => m.OperationModule),
        data: { permission: [Permission.OPERATION.FORMDATA.VIEW, Permission.OPERATION.FORMDATA.VIEWALL] },
        canActivateChild: [PermissionGuard],
      },
      {
        path: 'report-data',
        loadChildren: () => import('./report-data/report-data.module').then((m) => m.ReportDataModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.REPORT.VIEW_REPORT_PLANT_CDL, Permission.REPORT.VIEW_REPORT_PLANT_IN, Permission.REPORT.EXCELREPORT] },
      },
      {
        path: 'home',
        component: HomePageComponent,
      },
      {
        path: 'activity-log',
        loadChildren: () => import('./activity-log/activity-log.module').then((m) => m.ActivityLogModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.ACTIVITYLOG.VIEW] },
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
        canActivateChild: [PermissionGuard],
        data: { permission: [Permission.REPORT.VIEW_REPORT_PLANT_CDL, Permission.REPORT.VIEW_REPORT_PLANT_IN, Permission.REPORT.EXCELREPORT] },
      },
      {
        path: 'warning-machine',
        loadChildren: () => import('./warning-machine/machine-warning.module').then((m) => m.MachineWarningModule),
      },
      {
        path: 'mode-test',
        loadChildren: () => import('./mode-test-machine/mode-test.module').then((m) => m.ModeTestMachineModule),
      },
      {
        path: '',
        // component: HomePageComponent
        redirectTo: 'home',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: '',
      },
    ],
  },
];
