<div class="container">
  <div class="wrapper">
    <img alt="Logo image" [src]="this.appConfig.tenantLogo" />
    <h1>{{ 'APP_NAME' | translate }}</h1>
    <h2>{{ 'WELCOME_TITLE' | translate }}</h2>
    <p>{{ 'APP_DESCRIPTION' | translate }}</p>
    <div class="footer">
      <a class="mt-8 mr-16" mat-flat-button [color]="'primary'" [routerLink]="'/pages/dashboard/overview'" *ngIf="this.dashboardPermission">
        <mat-icon class="mr-8 white-fg">dashboard</mat-icon>
        <span>{{ 'DASHBOARD' | translate }}</span>
      </a>
      <a class="mt-8" mat-flat-button [color]="'accent'" [routerLink]="'/pages/help/training'">
        <mat-icon class="mr-8 white-fg">help_center</mat-icon>
        <span>{{ 'NAV.HELP.TRAINING' | translate }}</span>
      </a>
    </div>
  </div>
</div>
