import { Injectable } from '@angular/core';
import { CustomHttpClient } from '@services/http';
import { Observable, map } from 'rxjs';
export interface INotification {
  createdOnDate: string;
  description: string;
  id: number;
  isSeen: boolean;
  link: string;
  title: string;
  params: string;
}
@Injectable()
export class NotificationService {
  constructor(private _http: CustomHttpClient) {}

  public getNotifications(params?): Observable<INotification[]> {
    params = params || {};
    params.pageIndex = params.pageIndex || 1;
    params.pageSize = params.pageSize || 20;
    params.sortField = 'id';
    params.orderDescending = true;
    return this._http.Get('/notifications', { params }).pipe(
      map((resp: any) => {
        return resp.items || [];
      })
    );
  }

  public updateNotificationStatus(id) {
    return this._http.Patch(`/notifications/${id}/update-status-seen`);
  }

  public deleteNotification(id) {
    return this._http.Delete(`/notifications/${id}`);
  }

  // public endImpersonate() {
  //   return this._http.Get('/users/Logout');
  // }
}
